import React from 'react'
import Header from '../components/header/HeaderTen'
import RansomewareBanner from '../components/Ransomeware-protection/RansomewareProtectionBanner'
import Ransomewarefaq from '../components/Ransomeware-protection/RansomewareFaq'
import RansomewareFeature from '../components/Ransomeware-protection/RansomewareFeature'
import RansomewareFaqTwo from '../components/Ransomeware-protection/RansomewareFaqTwo'
import RansomewareFeatureOne from '../components/Ransomeware-protection/RansomewareFeatureOne'
import RansomewareFeatureTwo from '../components/Ransomeware-protection/RansomewareFeatureTwo'
import RansomewareFeatureThree from '../components/Ransomeware-protection/RansomewareFeatureThree'
import RansomewareFeatureFour from '../components/Ransomeware-protection/RansomewareFeatureFour'
import RansomewareFeatureFive from '../components/Ransomeware-protection/RansomewareFeatureFive';
import RansomewareFaqThree from '../components/Ransomeware-protection/RansomewareFaqThree'
import HomeEightCta from '../components/home-eight/HomeEightCta'
import HomeTenFooter from '../components/footer/HomeTenFooter'

function RansomwareProtection() {
  return (
    <div className='wordpress-hosting page-template'>
        <Header />
        <RansomewareBanner />
        <Ransomewarefaq/>
        <RansomewareFeature />
        {/*<HomeFourPricing />*/}
        <RansomewareFaqTwo />
        <RansomewareFeatureOne/>
        <RansomewareFeatureTwo/>
        <RansomewareFeatureThree/>
        <RansomewareFeatureFour/>
        <RansomewareFeatureFive/>
        {/*<WordPressHostingPlugins/>
        <AboutTestimonials className="bg-white pb--0"/>
        <PricingFaq />*/}
        <RansomewareFaqThree/>
        <HomeEightCta />
        <HomeTenFooter />
    </div>
  )
}

export default RansomwareProtection