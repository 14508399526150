import React from 'react'

function SecurityfeatureFour() {
    return (
        <>
            <div className="rts-hosting-feature bg-white section__padding pb-0 sc-pd">
                <div className="container">
                    <div className="row align-items-center">
                    <div
                            className="col-lg-6 col-xl-6 order-lg-1 order-xl-1 order-md-2 order-sm-2  order-2 "
                            data-sal="slide-left"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            <div className="hosting-feature-image ">
                                <div className="hosting-feature-image__image2 wordpress">
                                    <div className="h-img-two text-start">
                                        <img
                                            src="https://addicnet.com/wp-content/uploads/2024/06/Secure-All-Inbound-Emails.jpg"
                                            alt=""
                                        />
                                        
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 order-lg-2 order-xl-2 order-md-1 order-sm-1  order-1 ">
                            <div className="hosting-feature wordpress">
                                <h2
                                    className="hosting-feature__title hhosting-title"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Building Awareness Culture
                                </h2>
                                <p
                                    className="hosting-feature__desc hhosting-desc"
                                    data-sal="slide-down"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    Guardz ensures up-to-date employee training by notifying admins of training status and recommending industry-standard best practices. Customizable campaign planning allows businesses to target specific users and meet company goals.
                                    <br/><br/>Phishing simulations help employees learn to avoid threats like ransomware and malware, while mandatory evaluations measure the impact of training.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </>
    )
}

export default SecurityfeatureFour