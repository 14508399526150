import React from 'react'

function SecurityfeatureFive() {
    return (
        <>
            <div className="rts-hosting-feature bg-white section__padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6">
                            <div className="hosting-feature wordpress">
                                <h2
                                    className="hosting-feature__title hhosting-title"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    AI Phishing Simulation
                                </h2>
                                <p
                                    className="hosting-feature__desc hhosting-desc"
                                    data-sal="slide-down"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    Leveraging generative AI, Guardz has moved beyond the static library approach, where users had to choose from a predefined list of emails. Now, our advanced technology enables you to generate custom content on the fly for each department based on unique guidelines.
                                    <br/><br/> The Guardz generative AI model is constantly trained and updated with the latest information on real-world phishing attacks, ensuring highly accurate simulations. This seamless approach ensures employees are aware and well-equipped to handle emerging threats.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 col-xl-6"
                            data-sal="slide-left"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            <div className="hosting-feature-image">
                                <div className="hosting-feature-image__image2 wordpress">
                                    <div className="h-img-two text-center">
                                        <img
                                            src="https://addicnet.com/wp-content/uploads/2024/06/Secure-All-Inbound-Emails.jpg"
                                            alt=""
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SecurityfeatureFive