import React from 'react'
import Header from '../components/header/HeaderTen'
import WordPressHostingBanner from '../components/wordpress-hosting/WordPressHostingBanner'
import WordPressHostingFeature from '../components/wordpress-hosting/WordPressHostingFeature'
import HomeFourPricing from '../components/home-four/HomeFourPricing'
import SecurityfeatureOne from '../components/wordpress-hosting/SecurityfeatureOne'
import SecurityfeatureTwo from '../components/wordpress-hosting/SecurityfeatureTwo'
import SecurityfeatureThree from '../components/wordpress-hosting/SecurityfeatureThree'
import SecurityfeatureFour from '../components/wordpress-hosting/SecurityfeatureFour'
import SecurityfeatureFive from '../components/wordpress-hosting/SecurityfeatureFive';
import WordPressHostingPlugins from '../components/wordpress-hosting/WordPressHostingPlugins'
import AboutTestimonials from '../components/about/AboutTestimonials'
import PricingFaq from '../components/pricing/PricingFaq'
import SecurityFaq from '../components/wordpress-hosting/securityfaq'
import SecurityFaqtwo from '../components/wordpress-hosting/securityfaqtwo'
import SecurityFaqthree from '../components/wordpress-hosting/securityfaqthree'
import HomeEightCta from '../components/home-eight/HomeEightCta'
import HomeTenFooter from '../components/footer/HomeTenFooter'

function WordPressHosting() {
  return (
    <div className='wordpress-hosting page-template'>
        <Header />
        <WordPressHostingBanner />
        <SecurityFaq/>
        <WordPressHostingFeature />
        {/*<HomeFourPricing />*/}
        <SecurityFaqtwo />
        <SecurityfeatureOne/>
        <SecurityfeatureTwo/>
        <SecurityfeatureThree/>
        <SecurityfeatureFour/>
        <SecurityfeatureFive/>
        {/*<WordPressHostingPlugins/>
        <AboutTestimonials className="bg-white pb--0"/>
        <PricingFaq />*/}
        <SecurityFaqthree/>
        <HomeEightCta />
        <HomeTenFooter />
    </div>
  )
}

export default WordPressHosting