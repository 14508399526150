import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import sal from 'sal.js';
import 'sal.js/dist/sal.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
function HomeSixFeature() {
    useEffect(() => {
		sal({
			threshold: 0.1,
			once: true,
		});
	}, []);
    return (
        <>
            <section className="rts-feature-six section__padding">
                <div className="container">
                    <div className="row">
                        <div className="rts-section text-center">
                            <h2
                                className="rts-section__title"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                Hostie Helps Succeed
                            </h2>
                            <p
                                className="rts-section__description"
                                data-sal="slide-down"
                                data-sal-delay={300}
                                data-sal-duration={800}
                            >
                                Grow your website faster by using Hostie as your foundation.
                            </p>
                        </div>
                    </div>
                    <div className="row gy-30">
                        <div
                            className="col-lg-3 col-md-6"
                            data-sal="slide-down"
                            data-sal-delay={500}
                            data-sal-duration={800}
                        >
                            <div className="single__feature">
                                <div className="single__feature--box">
                                    <div className="single__feature--box-icon">
                                        <svg
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_415_2912)">
                                                <path
                                                    d="M37.6562 19.1596V14.4437C37.6562 12.1268 35.8127 10.2335 33.5156 10.1508V7.2562C33.5156 5.74846 32.289 4.52182 30.7812 4.52182H23.2533L22.2812 1.81971C22.0333 1.13065 21.5316 0.580026 20.8684 0.269323C20.2053 -0.0414587 19.4611 -0.0745838 18.773 0.175885L5.1125 5.14784C4.42516 5.39799 3.8768 5.90104 3.56836 6.56432C3.25992 7.2276 3.22859 7.97112 3.48023 8.65799L4.02906 10.156C1.78414 10.2949 0 12.1644 0 14.4437V35.6937C0 38.063 1.92758 39.9906 4.29688 39.9906H33.3594C35.7287 39.9906 37.6562 38.063 37.6562 35.6937V30.8216C38.9795 30.6314 40 29.4907 40 28.1156V21.8656C40 20.4905 38.9795 19.3497 37.6562 19.1596ZM37.6562 28.1156C37.6562 28.331 37.481 28.5062 37.2656 28.5062H31.0156C29.0771 28.5062 27.5 26.9291 27.5 24.9906C27.5 23.0521 29.0771 21.4749 31.0156 21.4749H37.2656C37.481 21.4749 37.6562 21.6502 37.6562 21.8656V28.1156ZM5.68094 7.85174C5.63242 7.71932 5.66797 7.60753 5.69352 7.5526C5.71906 7.49776 5.78164 7.39846 5.91414 7.35026L19.5746 2.37831C19.7073 2.3301 19.8191 2.36596 19.874 2.39167C19.9289 2.41737 20.028 2.48034 20.0758 2.61315L20.7625 4.5219H20.2344C18.7266 4.5219 17.5 5.74854 17.5 7.25628V10.1469H6.5218L5.68094 7.85174ZM31.1719 7.2562V10.1468H19.8438V7.2562C19.8438 7.04081 20.019 6.86557 20.2344 6.86557H30.7812C30.9966 6.86557 31.1719 7.04081 31.1719 7.2562ZM33.3594 37.6468H4.29688C3.21992 37.6468 2.34375 36.7707 2.34375 35.6937V14.4437C2.34375 13.3667 3.21992 12.4906 4.29688 12.4906H33.3594C34.4363 12.4906 35.3125 13.3667 35.3125 14.4437V19.1312H31.0156C27.7848 19.1312 25.1562 21.7597 25.1562 24.9906C25.1562 28.2214 27.7848 30.8499 31.0156 30.8499H35.3125V35.6937C35.3125 36.7707 34.4363 37.6468 33.3594 37.6468Z"
                                                    fill="#2D3C58"
                                                />
                                                <path
                                                    d="M31.0156 26.1624C31.6628 26.1624 32.1875 25.6377 32.1875 24.9905C32.1875 24.3433 31.6628 23.8187 31.0156 23.8187C30.3684 23.8187 29.8438 24.3433 29.8438 24.9905C29.8438 25.6377 30.3684 26.1624 31.0156 26.1624Z"
                                                    fill="#2D3C58"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_415_2912">
                                                    <rect width={40} height={40} fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <h5 className="single__feature--box-title">
                                        Money-Back Guaranteed
                                    </h5>
                                    <p className="single__feature--box-description">
                                        We guarantee it you don't have to worry about it.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                            data-sal="slide-down"
                            data-sal-delay={600}
                            data-sal-duration={800}
                        >
                            <div className="single__feature">
                                <div className="single__feature--box">
                                    <div className="single__feature--box-icon">
                                        <svg
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.8333 0C30.787 0 39.6667 8.87969 39.6667 19.8333C39.6667 30.787 30.787 39.6667 19.8333 39.6667C8.87969 39.6667 0 30.787 0 19.8333C0 8.87969 8.87969 0 19.8333 0ZM19.8333 2.08772C10.0327 2.08772 2.08772 10.0327 2.08772 19.8333C2.08772 29.634 10.0327 37.579 19.8333 37.579C29.634 37.579 37.579 29.634 37.579 19.8333C37.579 10.0327 29.634 2.08772 19.8333 2.08772ZM31.0101 8.65662C31.3864 9.03291 31.4542 9.71168 31.0101 10.1329L23.4307 17.7122C23.798 18.3339 24.0088 19.059 24.0088 19.8333C24.0088 22.1394 22.1394 24.0088 19.8333 24.0088C17.5273 24.0088 15.6579 22.1394 15.6579 19.8333C15.6579 17.5273 17.5273 15.6579 19.8333 15.6579C20.6077 15.6579 21.3328 15.8687 21.9545 16.236L29.5338 8.65662C29.9415 8.24897 30.6024 8.24897 31.0101 8.65662ZM19.8333 17.7456C18.6803 17.7456 17.7456 18.6803 17.7456 19.8333C17.7456 20.9864 18.6803 21.9211 19.8333 21.9211C20.9864 21.9211 21.9211 20.9864 21.9211 19.8333C21.9211 18.6803 20.9864 17.7456 19.8333 17.7456ZM32.1693 14.1715C32.9788 15.9325 33.4035 17.8539 33.4035 19.8333C33.4035 20.4098 32.9362 20.8772 32.3597 20.8772C31.7831 20.8772 31.3158 20.4098 31.3158 19.8333C31.3158 18.1565 30.9568 16.5323 30.2724 15.0435C30.0316 14.5197 30.2611 13.8998 30.7849 13.659C31.3087 13.4183 31.9285 13.6477 32.1693 14.1715ZM19.8333 6.26316C21.831 6.26316 23.7696 6.6958 25.5435 7.51973C26.0664 7.76258 26.2934 8.38331 26.0506 8.90617C25.8077 9.42904 25.187 9.65604 24.6641 9.41319C23.1642 8.71657 21.5257 8.35088 19.8333 8.35088C13.4918 8.35088 8.35088 13.4918 8.35088 19.8333C8.35088 20.4098 7.88353 20.8772 7.30702 20.8772C6.73051 20.8772 6.26316 20.4098 6.26316 19.8333C6.26316 12.3387 12.3387 6.26316 19.8333 6.26316Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="single__feature--box-title">Fast &amp; Reliable</h5>
                                    <p className="single__feature--box-description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                            data-sal="slide-down"
                            data-sal-delay={700}
                            data-sal-duration={800}
                        >
                            <div className="single__feature">
                                <div className="single__feature--box">
                                    <div className="single__feature--box-icon">
                                        <svg
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M3.77599 0.159466C3.77701 0.159822 3.77805 0.16018 3.77907 0.160536L38.0902 12.0366C38.0909 12.0369 38.0917 12.0373 38.0925 12.0374C40.7087 12.9357 40.5929 16.6231 37.9963 17.4302L22.4859 22.2151C22.4698 22.2201 22.4538 22.2246 22.4377 22.2288C22.3866 22.2425 22.3399 22.2689 22.3023 22.3059C22.2647 22.3428 22.2373 22.3889 22.2228 22.4397L22.2152 22.4651L17.4302 37.9963C16.6231 40.5929 12.9357 40.7087 12.0374 38.0925C12.0373 38.0917 12.0369 38.0909 12.0366 38.0902L0.160536 3.77907C0.16018 3.77805 0.159822 3.77701 0.159466 3.77599C-0.0169322 3.27222 -0.0476967 2.72892 0.0707236 2.20842C0.189387 1.68686 0.453019 1.20947 0.831244 0.831244C1.20947 0.453019 1.68686 0.189387 2.20842 0.0707236C2.72892 -0.0476967 3.27222 -0.0169322 3.77599 0.159466ZM1.30805 3.37579L2.45745 2.97794L14.3367 37.2983L14.338 37.302C14.3766 37.4147 14.4324 37.4709 14.4834 37.5043C14.5424 37.5429 14.6246 37.569 14.7195 37.567C14.8144 37.5653 14.8984 37.5359 14.9598 37.4924C15.0137 37.4543 15.0703 37.3924 15.1069 37.2751C15.1069 37.2753 15.1071 37.2748 15.1069 37.2751L19.8872 21.7597C20.0182 21.3085 20.2631 20.8985 20.5987 20.5695C20.931 20.2435 21.3408 20.0077 21.7892 19.8843L37.2743 15.1072C37.274 15.1072 37.2745 15.1071 37.2743 15.1072C37.3917 15.0706 37.4543 15.0137 37.4924 14.9598C37.5359 14.8984 37.5653 14.8144 37.567 14.7195C37.569 14.6246 37.5429 14.5424 37.5043 14.4834C37.4709 14.4324 37.4147 14.3766 37.302 14.338L37.2983 14.3367L2.97249 2.45557C2.9004 2.43024 2.82262 2.42578 2.7481 2.44273C2.6736 2.45968 2.60541 2.49735 2.55137 2.55137C2.49735 2.60541 2.45968 2.6736 2.44273 2.7481C2.42578 2.82262 2.43022 2.9004 2.45556 2.97249L1.30805 3.37579Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="single__feature--box-title">Super Easy to Use</h5>
                                    <p className="single__feature--box-description">
                                        Our custom control panel to use and removes the headache
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6"
                            data-sal="slide-down"
                            data-sal-delay={800}
                            data-sal-duration={800}
                        >
                            <div className="single__feature">
                                <div className="single__feature--box">
                                    <div className="single__feature--box-icon">
                                        <svg
                                            width={80}
                                            height={80}
                                            viewBox="0 0 80 80"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx={40} cy={40} r={40} fill="#EAF1FF" />
                                            <path
                                                d="M21.8189 40.0032C21.8184 36.5884 22.7795 33.2425 24.5923 30.3487C26.4051 27.4548 28.9962 25.13 32.069 23.6404C35.1417 22.1508 38.5718 21.5566 41.9665 21.9259C45.3613 22.2953 48.5834 23.6132 51.2639 25.7287H48.4978C48.2582 25.731 48.0292 25.8278 47.8606 25.9981C47.692 26.1683 47.5974 26.3983 47.5974 26.6379C47.5974 26.8775 47.692 27.1074 47.8606 27.2777C48.0292 27.4479 48.2582 27.5448 48.4978 27.5471H53.641C53.8821 27.5471 54.1134 27.4513 54.2839 27.2808C54.4544 27.1103 54.5502 26.879 54.5502 26.6378V21.4945C54.5502 21.2534 54.4544 21.0221 54.2839 20.8516C54.1134 20.6811 53.8821 20.5853 53.641 20.5853C53.3999 20.5853 53.1686 20.6811 52.9981 20.8516C52.8276 21.0221 52.7318 21.2534 52.7318 21.4945V24.5733C48.7673 21.3025 43.6958 19.6832 38.5694 20.0514C33.443 20.4196 28.6551 22.747 25.1987 26.5508C21.7423 30.3546 19.8828 35.3429 20.0057 40.481C20.1287 45.6192 22.2248 50.5128 25.8592 54.1469C26.0297 54.3174 26.2609 54.4131 26.502 54.4131C26.7431 54.4131 26.9744 54.3174 27.1449 54.1469C27.3153 53.9764 27.4111 53.7452 27.4111 53.504C27.4111 53.2629 27.3153 53.0317 27.1449 52.8612C25.451 51.1766 24.1081 49.1728 23.1939 46.9657C22.2797 44.7586 21.8123 42.3921 21.8189 40.0032Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M32.0564 56.3648C31.5405 56.1139 31.0299 55.8347 30.5391 55.5355C30.3333 55.4099 30.086 55.3711 29.8517 55.4278C29.6173 55.4845 29.4151 55.632 29.2894 55.8378C29.1638 56.0436 29.125 56.2909 29.1817 56.5253C29.2384 56.7596 29.3859 56.9619 29.5917 57.0875C30.132 57.4174 30.694 57.7242 31.2619 58.0003C31.4784 58.103 31.7267 58.116 31.9528 58.0366C32.1789 57.9571 32.3644 57.7916 32.4692 57.5761C32.5739 57.3606 32.5893 57.1124 32.512 56.8856C32.4348 56.6587 32.2711 56.4716 32.0565 56.3648H32.0564Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M56.3729 32.0735C56.4249 32.1809 56.4976 32.2771 56.5868 32.3564C56.676 32.4358 56.78 32.4968 56.8928 32.536C57.0056 32.5752 57.125 32.5918 57.2442 32.5848C57.3634 32.5778 57.48 32.5475 57.5875 32.4954C57.695 32.4434 57.7911 32.3706 57.8705 32.2814C57.9498 32.1922 58.0108 32.0883 58.05 31.9755C58.0892 31.8627 58.1058 31.7433 58.0988 31.6241C58.0919 31.5049 58.0615 31.3882 58.0094 31.2808C57.7355 30.7147 57.4292 30.1527 57.0993 29.6101C57.0373 29.5081 56.9557 29.4193 56.8594 29.3488C56.763 29.2783 56.6536 29.2274 56.5376 29.1992C56.3033 29.1421 56.0559 29.1805 55.8498 29.3058C55.6438 29.4311 55.496 29.6332 55.4389 29.8675C55.3818 30.1019 55.4202 30.3493 55.5455 30.5553C55.8454 31.0486 56.1238 31.5594 56.3729 32.0735Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M42.1599 58.0599C41.5859 58.1281 41.0052 58.1687 40.4334 58.1824C40.1957 58.1901 39.9705 58.2906 39.806 58.4624C39.6416 58.6343 39.551 58.8637 39.5538 59.1015C39.5565 59.3394 39.6524 59.5666 39.8208 59.7346C39.9892 59.9026 40.2167 59.9978 40.4546 60H40.4763C41.1049 59.9853 41.7428 59.9403 42.3733 59.8659C42.4919 59.852 42.6067 59.8149 42.711 59.7567C42.8154 59.6984 42.9072 59.6202 42.9813 59.5264C43.0553 59.4326 43.1102 59.3252 43.1427 59.2102C43.1752 59.0952 43.1847 58.9749 43.1707 58.8563C43.1567 58.7376 43.1194 58.6229 43.0611 58.5186C43.0027 58.4144 42.9243 58.3226 42.8305 58.2487C42.7366 58.1747 42.6291 58.12 42.5141 58.0876C42.3991 58.0552 42.2788 58.0458 42.1602 58.06L42.1599 58.0599Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M36.9857 57.9365C36.4201 57.8418 35.8514 57.7189 35.2956 57.5699C35.1801 57.5388 35.0597 57.5307 34.9411 57.5462C34.8225 57.5617 34.7081 57.6004 34.6045 57.6602C34.5009 57.7199 34.4101 57.7995 34.3373 57.8944C34.2645 57.9893 34.2112 58.0976 34.1803 58.2131C34.1494 58.3286 34.1416 58.4491 34.1573 58.5677C34.1731 58.6862 34.212 58.8005 34.272 58.904C34.332 59.0074 34.4118 59.0981 34.5068 59.1707C34.6018 59.2433 34.7102 59.2964 34.8258 59.327C35.4377 59.4904 36.0636 59.6259 36.6865 59.7297C36.8045 59.75 36.9254 59.7467 37.0422 59.7201C37.1589 59.6936 37.2693 59.6442 37.3669 59.5748C37.4646 59.5055 37.5475 59.4175 37.6111 59.316C37.6746 59.2145 37.7174 59.1014 37.7371 58.9833C37.7569 58.8651 37.753 58.7443 37.7259 58.6276C37.6987 58.511 37.6488 58.4009 37.579 58.3036C37.5092 58.2063 37.4208 58.1237 37.319 58.0607C37.2172 57.9977 37.1039 57.9553 36.9857 57.9362V57.9365Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M59.1133 39.5648C58.8723 39.5589 58.6388 39.649 58.4641 39.8152C58.2894 39.9815 58.188 40.2103 58.1819 40.4513C58.1673 41.0263 58.1255 41.607 58.0572 42.1778C58.0287 42.4173 58.0964 42.6583 58.2455 42.8478C58.3946 43.0374 58.6128 43.16 58.8523 43.1887C58.8885 43.1928 58.925 43.1949 58.9614 43.195C59.1837 43.1946 59.3981 43.1129 59.5642 42.9653C59.7304 42.8177 59.8367 42.6144 59.8632 42.3937C59.9378 41.7664 59.9839 41.1285 59.9996 40.4967C60.0026 40.3773 59.9821 40.2585 59.9392 40.147C59.8963 40.0355 59.8319 39.9336 59.7496 39.8471C59.6673 39.7606 59.5687 39.6911 59.4595 39.6427C59.3504 39.5942 59.2327 39.5678 59.1133 39.5648Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M56.3224 49.9645C56.1229 49.8293 55.8778 49.7787 55.641 49.8239C55.4043 49.8691 55.1951 50.0063 55.0594 50.2056C54.7362 50.681 54.3868 51.1467 54.0201 51.5893C53.9422 51.681 53.8832 51.7873 53.8467 51.902C53.8101 52.0167 53.7967 52.1375 53.8072 52.2574C53.8177 52.3773 53.8519 52.4939 53.9078 52.6005C53.9638 52.7071 54.0403 52.8015 54.133 52.8782C54.2257 52.955 54.3328 53.0126 54.4479 53.0476C54.5631 53.0827 54.684 53.0945 54.8038 53.0825C54.9235 53.0704 55.0397 53.0347 55.1456 52.9774C55.2514 52.92 55.3448 52.8423 55.4203 52.7486C55.8234 52.262 56.2084 51.7502 56.5635 51.2272C56.6306 51.1284 56.6776 51.0175 56.7018 50.9006C56.7259 50.7837 56.7269 50.6632 56.7045 50.5459C56.6821 50.4287 56.6368 50.317 56.5713 50.2172C56.5057 50.1175 56.4212 50.0316 56.3224 49.9645Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M57.5747 35.3125C57.7221 35.8666 57.8446 36.4347 57.9392 37.003C57.9744 37.2153 58.0838 37.4082 58.2478 37.5474C58.4118 37.6867 58.6199 37.7632 58.8351 37.7635C58.8852 37.7634 58.9352 37.7592 58.9847 37.7511C59.1025 37.7315 59.2153 37.689 59.3167 37.6258C59.418 37.5627 59.506 37.4802 59.5754 37.3831C59.6449 37.2859 59.6945 37.1761 59.7215 37.0598C59.7485 36.9434 59.7523 36.8229 59.7327 36.7051C59.6293 36.0801 59.4941 35.4539 59.3321 34.8445C59.2688 34.613 59.1164 34.4159 58.9083 34.2963C58.7001 34.1766 58.4531 34.1441 58.2212 34.2059C57.9892 34.2677 57.791 34.4187 57.67 34.626C57.5489 34.8333 57.5148 35.0801 57.5749 35.3125H57.5747Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M51.5729 54.0324C51.1284 54.3999 50.6628 54.7493 50.189 55.0703C50.0902 55.1373 50.0055 55.2231 49.9399 55.3228C49.8742 55.4225 49.8288 55.5342 49.8063 55.6514C49.7838 55.7687 49.7846 55.8892 49.8087 56.0061C49.8328 56.1231 49.8797 56.2341 49.9466 56.3329C50.0136 56.4317 50.0994 56.5164 50.1991 56.5821C50.2988 56.6477 50.4105 56.6931 50.5277 56.7156C50.645 56.7381 50.7655 56.7373 50.8824 56.7132C50.9994 56.6892 51.1104 56.6423 51.2092 56.5753C51.7305 56.2223 52.2423 55.8383 52.7316 55.4343C52.8243 55.3584 52.901 55.265 52.9575 55.1593C53.0139 55.0537 53.049 54.938 53.0606 54.8187C53.0722 54.6995 53.0601 54.5792 53.0251 54.4647C52.99 54.3501 52.9327 54.2436 52.8564 54.1513C52.7801 54.059 52.6863 53.9827 52.5804 53.9267C52.4745 53.8707 52.3586 53.8362 52.2393 53.8252C52.1201 53.8141 51.9998 53.8267 51.8854 53.8623C51.7711 53.8979 51.6648 53.9557 51.5729 54.0324Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M58.4642 44.9652C58.2345 44.8922 57.9853 44.9134 57.7712 45.024C57.557 45.1346 57.3955 45.3256 57.3221 45.5551C57.1481 46.1006 56.9447 46.6455 56.7179 47.1751C56.6683 47.2852 56.6411 47.4041 56.638 47.5248C56.6349 47.6456 56.6558 47.7657 56.6996 47.8782C56.7434 47.9908 56.8092 48.0934 56.8931 48.1803C56.9771 48.2671 57.0775 48.3363 57.1884 48.3839C57.2994 48.4315 57.4188 48.4565 57.5395 48.4575C57.6603 48.4585 57.78 48.4353 57.8918 48.3895C58.0035 48.3437 58.105 48.2761 58.1903 48.1906C58.2756 48.1051 58.343 48.0035 58.3886 47.8916C58.6389 47.3087 58.8626 46.708 59.054 46.1074C59.0904 45.9937 59.1039 45.8739 59.094 45.755C59.084 45.636 59.0507 45.5201 58.9959 45.4141C58.9411 45.308 58.866 45.2137 58.7748 45.1367C58.6835 45.0597 58.578 45.0014 58.4642 44.9652Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M47.1577 56.7253C46.6333 56.95 46.0882 57.1529 45.5373 57.3287C45.4235 57.3649 45.318 57.4232 45.2268 57.5003C45.1356 57.5773 45.0605 57.6715 45.0057 57.7776C44.9509 57.8837 44.9175 57.9995 44.9075 58.1185C44.8975 58.2374 44.911 58.3572 44.9472 58.4709C44.9835 58.5847 45.0418 58.6902 45.1188 58.7814C45.1959 58.8726 45.2901 58.9477 45.3962 59.0025C45.5023 59.0573 45.6181 59.0907 45.737 59.1007C45.856 59.1107 45.9758 59.0972 46.0895 59.061C46.6956 58.8679 47.2957 58.6446 47.8733 58.3973C48.095 58.3023 48.2699 58.1232 48.3596 57.8993C48.4492 57.6754 48.4462 57.425 48.3513 57.2033C48.2563 56.9816 48.0772 56.8067 47.8533 56.7171C47.6294 56.6274 47.379 56.6304 47.1573 56.7253H47.1577Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M32.3134 38.1456C32.3134 36.6556 31.3331 35.4616 29.817 35.1035C28.9839 34.9062 28.1085 34.9971 27.3338 35.3614C26.559 35.7257 25.9306 36.3418 25.5511 37.1092C25.4977 37.216 25.4658 37.3323 25.4574 37.4514C25.4489 37.5706 25.464 37.6902 25.5017 37.8035C25.5395 37.9167 25.5992 38.0215 25.6775 38.1117C25.7557 38.2019 25.8509 38.2758 25.9578 38.3292C26.0646 38.3826 26.1808 38.4145 26.3 38.423C26.4191 38.4314 26.5387 38.4163 26.652 38.3786C26.7653 38.3408 26.87 38.2811 26.9602 38.2029C27.0504 38.1246 27.1244 38.0294 27.1778 37.9226C27.3719 37.5197 27.6984 37.1957 28.1028 37.0047C28.5071 36.8137 28.9648 36.7673 29.3992 36.8733C29.6563 36.9339 30.4949 37.2051 30.4949 38.1456C30.4949 39.0939 29.3997 39.8253 28.2401 40.5998C26.9345 41.4713 25.4552 42.4591 25.4552 44.0946C25.4552 44.3357 25.551 44.567 25.7215 44.7375C25.892 44.908 26.1233 45.0038 26.3644 45.0038H31.4041C31.6453 45.0038 31.8765 44.908 32.047 44.7375C32.2176 44.567 32.3134 44.3357 32.3134 44.0946C32.3134 43.8534 32.2176 43.6222 32.047 43.4516C31.8765 43.2811 31.6453 43.1853 31.4041 43.1853H27.802C28.1828 42.8244 28.7213 42.4648 29.2496 42.1119C30.686 41.153 32.3134 40.0662 32.3134 38.1456Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M40.9118 41.1396C40.9118 40.8984 40.816 40.6672 40.6455 40.4967C40.475 40.3262 40.2437 40.2304 40.0026 40.2304H39.2638V35.4002C39.2638 35.2119 39.2053 35.0283 39.0964 34.8747C38.9876 34.721 38.8337 34.605 38.656 34.5425C38.4784 34.4801 38.2857 34.4743 38.1047 34.5261C37.9236 34.5778 37.7631 34.6845 37.6453 34.8314L33.0424 40.5709C32.9353 40.7046 32.8681 40.8658 32.8487 41.036C32.8292 41.2062 32.8582 41.3785 32.9323 41.5329C33.0065 41.6874 33.1227 41.8178 33.2677 41.909C33.4127 42.0003 33.5805 42.0487 33.7518 42.0488H37.4455V44.6059C37.4443 44.7261 37.467 44.8453 37.5121 44.9566C37.5573 45.0679 37.6241 45.1692 37.7086 45.2546C37.7932 45.3399 37.8938 45.4077 38.0047 45.4539C38.1156 45.5002 38.2345 45.524 38.3546 45.524C38.4748 45.524 38.5937 45.5002 38.7046 45.4539C38.8155 45.4077 38.9161 45.3399 39.0007 45.2546C39.0852 45.1692 39.152 45.0679 39.1972 44.9566C39.2423 44.8453 39.265 44.7261 39.2638 44.6059V42.0488H40.0026C40.2437 42.0488 40.475 41.953 40.6455 41.7825C40.816 41.612 40.9118 41.3807 40.9118 41.1396ZM37.4455 40.2304H35.6469L37.4455 37.9871V40.2304Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M45.5659 33.9975C45.4488 33.9738 45.3282 33.9735 45.211 33.9966C45.0938 34.0196 44.9823 34.0656 44.8829 34.1319C44.7835 34.1981 44.6981 34.2834 44.6317 34.3827C44.5653 34.482 44.5192 34.5935 44.496 34.7107L42.4503 44.9392C42.4269 45.0563 42.4267 45.1768 42.4499 45.2939C42.473 45.4111 42.519 45.5225 42.5852 45.6219C42.6514 45.7212 42.7365 45.8065 42.8357 45.873C42.9349 45.9395 43.0462 45.9857 43.1633 46.0092C43.2804 46.0326 43.4009 46.0328 43.518 46.0096C43.6351 45.9865 43.7466 45.9405 43.8459 45.8743C43.9453 45.8081 44.0306 45.7229 44.0971 45.6238C44.1635 45.5246 44.2098 45.4133 44.2332 45.2962L46.2789 35.0676C46.3025 34.9505 46.3027 34.83 46.2796 34.7128C46.2564 34.5956 46.2105 34.4842 46.1442 34.3848C46.078 34.2854 45.9929 34.2 45.8936 34.1336C45.7944 34.0671 45.683 34.0209 45.5659 33.9975Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M50.2315 45.0042C50.411 45.0041 50.5865 44.9509 50.7359 44.8512C50.8853 44.7516 51.0019 44.6101 51.071 44.4443L54.4805 36.2615C54.5381 36.1233 54.5608 35.973 54.5463 35.824C54.5319 35.6749 54.4809 35.5318 54.3979 35.4072C54.3148 35.2826 54.2022 35.1805 54.0702 35.1099C53.9382 35.0393 53.7907 35.0024 53.641 35.0024H49.0381C48.797 35.0024 48.5657 35.0982 48.3952 35.2688C48.2247 35.4393 48.1289 35.6705 48.1289 35.9117C48.1289 36.1528 48.2247 36.3841 48.3952 36.5546C48.5657 36.7251 48.797 36.8209 49.0381 36.8209H52.2772L49.3924 43.745C49.3351 43.8832 49.3127 44.0334 49.3272 44.1823C49.3418 44.3312 49.3928 44.4742 49.4757 44.5987C49.5587 44.7232 49.671 44.8253 49.8028 44.896C49.9347 44.9667 50.0819 45.0039 50.2315 45.0042Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="single__feature--box-title">24/7 Expert Support</h5>
                                    <p className="single__feature--box-description">
                                        Our custom control panel to use and removes the headache
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="plan__btn">
                            <Link to="/pricing">
                                See Our Hosting Plan <i className="fa-regular fa-arrow-right" />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeSixFeature