import React, { useState } from 'react'

function RansomewareFaqThree() {
    const [activeIndexThree, setActiveIndexThree] = useState(0); // Default to the first accordion item
  
    const handleToggleThree = (index) => {
      setActiveIndexThree((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            <div className="rts-hosting-faq section__padding pb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="rts-section text-center">
                            <h2
                                className="rts-section__title mb-0"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                Looking for Answers? <br/>
                                You're in the Right Place
                            </h2>
                        </div>
                        <div className="col-lg-8">
                            <div
                                className="rts-faq__accordion"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                <div className="accordion accordion-flush" id="rts-accordion">
                                    <div className={`accordion-item ${activeIndexThree === 0 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(0)}>
                                        <div className="accordion-header" id="one">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 0 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 0}
                                              >
                                               How can I recognize a ransomware attack?
                                            </h4>
                                        </div>
                                        <div
                                           id="item__one"
                                           className={`accordion-collapse collapse ${activeIndexThree === 0 ? "show" : ""}`}
                                           aria-labelledby="one"
                                           data-bs-parent="#rts-accordion"
                                         
                                        >
                                            <div className="accordion-body">
                                            Some common signs are an unexpected screen alert or pop-up message indication that your data has been encrypted, demands for a ransom payment in exchange for a decryption key, inability to access your files, and your system working slower than usual.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 1 ? "active" : ""}`}
                                    onClick={() => handleToggleThree(1)}>
                                        <div className="accordion-header" id="two">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 1 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 1}
                                            >
                                                Can I rely on email filters to protect me from phishing?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__two"
                                            className={`accordion-collapse collapse ${activeIndexThree === 1 ? "show" : ""}`}
                                            aria-labelledby="two"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            If you become a victim of a ransomware attack, it is advised not to pay the ransom. Instead, disconnect the affected device from your network to prevent the ransomware from spreading. Report the attack to the local authorities and get professional help to remove the ransomware. If possible, restore your files from a clean backup.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 2 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(2)}
                                    >
                                        <div className="accordion-header" id="three">
                                            <h4
                                                 className={`accordion-button ${activeIndexThree === 2 ? "" : "collapsed"}`}
                                                 aria-expanded={activeIndexThree === 2}
                                            >
                                               What should I do if I receive a phishing email?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__three"
                                            className={`accordion-collapse collapse ${activeIndexThree === 2 ? "show" : ""}`}
                                            aria-labelledby="three"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            If you receive a phishing email, take the following steps to protect against phishing attacks: Do not click on any links or download attachments to avoid falling victim to phishing attempts, do not reply or provide any personal information as it can be used against you, report the email as spam or phishing to your email provider to aid in phishing protection efforts, delete the email from your inbox to prevent accidental clicks on phishing links, If the email appears to be from a legitimate organization, contact them directly using official contact information to verify its authenticity.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 3 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(3)}
                                    >
                                        <div className="accordion-header" id="four">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 3 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 3}
                                            >
                                               Should I pay the ransom if I'm a victim of a ransomware attack?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__four"
                                            className={`accordion-collapse collapse ${activeIndexThree === 3 ? "show" : ""}`}
                                            aria-labelledby="four"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Paying the ransom doesn’t guarantee you will get your files back. It also pushes the cybercriminals to target more people and makes this kind of illegal activity attractive to others. Rather seek proffessional help before taking next steps.
                                            </div>
                                        </div>
                                    </div>   
                                    <div className={`accordion-item ${activeIndexThree === 4 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(4)}
                                    >
                                        <div className="accordion-header" id="five">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 4 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 4}
                                            >
                                            Can antivirus software prevent ransomware attacks?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__five"
                                            className={`accordion-collapse collapse ${activeIndexThree === 4 ? "show" : ""}`}
                                            aria-labelledby="five"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Antivirus programs can detect and remove many types of malware, including some ransomware strains. However, no solution is 100% effective, so practicing safe browsing habits and regular data backups is also crucial.
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`accordion-item ${activeIndexThree === 5 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(5)}
                                    >
                                        <div className="accordion-header" id="six">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 5 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 5}
                                            >
                                            How long does it take to recover from a ransomware attack?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__six"
                                            className={`accordion-collapse collapse ${activeIndexThree === 5 ? "show" : ""}`}
                                            aria-labelledby="six"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            The recovery time can vary widely depending on the extent of the attack and the availability of up-to-date backups. It can range from a few hours to several days or even weeks.
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`accordion-item ${activeIndexThree === 6 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(6)}
                                    >
                                        <div className="accordion-header" id="seven">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 6 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 6}
                                            >
                                            What is whaling in cybersecurity?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__seven"
                                            className={`accordion-collapse collapse ${activeIndexThree === 6 ? "show" : ""}`}
                                            aria-labelledby="seven"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Whaling in cybersecurity is a highly targeted phishing attack aimed specifically at high-level executives, CEOs, or other senior management within an organization. Cybercriminals use social engineering techniques, detailed research about the target, and carefully crafted emails or messages to impersonate trusted individuals or organizations, aiming to manipulate the executive into revealing sensitive information or transferring funds to the attacker’s account.
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`accordion-item ${activeIndexThree === 7 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(7)}
                                    >
                                        <div className="accordion-header" id="eight">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 7 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 7}
                                            >
                                            Secure All Inbound Emails
                                            </h4>
                                        </div>
                                        <div
                                            id="item__eight"
                                            className={`accordion-collapse collapse ${activeIndexThree === 7 ? "show" : ""}`}
                                            aria-labelledby="eight"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Guardz streamlines email protection with its fully managed, cloud-based solution that eliminates manual setup and seamlessly integrates with Microsoft 365 and Google Workspace accounts. Leveraging advanced predictive analysis, global intelligence tools, and continuous updates, Guardz provides robust defense against multiple attack vectors.
                                            <br/><br/>High-risk emails are immediately placed into quarantine to programmatically remove the threat. Employees receive warnings and recommended actions for potentially harmful emails, while IT admins can automatically flag and remove malicious messages.
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RansomewareFaqThree