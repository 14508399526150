import React from 'react'

function SecurityfeatureThree() {
    return (
        <>
            <div className="rts-hosting-feature bg-white section__padding pb-0 sc-pd">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6">
                            <div className="hosting-feature wordpress">
                                <h2
                                    className="hosting-feature__title hhosting-title"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Active Risk Prevention
                                </h2>
                                <p
                                    className="hosting-feature__desc hhosting-desc"
                                    data-sal="slide-down"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    The Guardz external active risk monitoring offers an essential component to protect against phishing attacks by conducting an automatic external surface scan and dark web analysis. By assessing a business’s digital footprint, the solution identifies exposed services like missing DNS records (DKIM, DMARC, SPF), invalid chains of trust (TLS/SSL), leaked credentials, and potential business targeting.
                                    <br/><br/> Guardz ensures that employees’ and clients’ digital assets remain secure from targeted phishing attacks that take advantage of external vulnerabilities while preventing domain spoofing, hijacking or squatting that are key vectors for cybercriminal exploitation.
                                    <br/><br/> By offering real-time alerts and comprehensive website analysis, the Guardz browser extension empowers employees to make informed decisions and navigate the internet safely.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 col-xl-6"
                            data-sal="slide-left"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            <div className="hosting-feature-image">
                                <div className="hosting-feature-image__image2 wordpress">
                                    <div className="h-img-two text-center">
                                        <img
                                            src="https://addicnet.com/wp-content/uploads/2024/06/Secure-All-Inbound-Emails.jpg"
                                            alt=""
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SecurityfeatureThree