import React, { useState } from 'react'

function DataLossFaqThree() {
    const [activeIndexThree, setActiveIndexThree] = useState(0); // Default to the first accordion item
  
    const handleToggleThree = (index) => {
      setActiveIndexThree((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            <div className="rts-hosting-faq section__padding pb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="rts-section text-center">
                            <h2
                                className="rts-section__title mb-0"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                Looking for Answers? <br/>
                                You're in the Right Place
                            </h2>
                        </div>
                        <div className="col-lg-8">
                            <div
                                className="rts-faq__accordion"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                <div className="accordion accordion-flush" id="rts-accordion">
                                    <div className={`accordion-item ${activeIndexThree === 0 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(0)}>
                                        <div className="accordion-header" id="one">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 0 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 0}
                                              >
                                               How does data loss prevention work?
                                            </h4>
                                        </div>
                                        <div
                                           id="item__one"
                                           className={`accordion-collapse collapse ${activeIndexThree === 0 ? "show" : ""}`}
                                           aria-labelledby="one"
                                           data-bs-parent="#rts-accordion"
                                         
                                        >
                                            <div className="accordion-body">
                                            Data loss prevention solutions work by identifying sensitive data and applying policies to control how the data is used or transferred. When a breach is identified, the system can automatically enforce corrective actions, such as encrypting data, alerting users/admins, or preventing data from being sent.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 1 ? "active" : ""}`}
                                    onClick={() => handleToggleThree(1)}>
                                        <div className="accordion-header" id="two">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 1 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 1}
                                            >
                                                Can Data Loss Prevention protect against both external and internal threats?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__two"
                                            className={`accordion-collapse collapse ${activeIndexThree === 1 ? "show" : ""}`}
                                            aria-labelledby="two"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Yes, data loss prevention can provide protection against both external threats like hacking attacks and internal threats such as employees inadvertently or deliberately leaking data.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 2 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(2)}
                                    >
                                        <div className="accordion-header" id="three">
                                            <h4
                                                 className={`accordion-button ${activeIndexThree === 2 ? "" : "collapsed"}`}
                                                 aria-expanded={activeIndexThree === 2}
                                            >
                                               Why do you need a Data Loss Prevention solution?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__three"
                                            className={`accordion-collapse collapse ${activeIndexThree === 2 ? "show" : ""}`}
                                            aria-labelledby="three"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            A data loss prevention system can help your organization prevent accidental or intentional data leaks. This includes vital customer information or company proprietary data. DLP offers your security team full insight into data movement, guarding data in transit or at rest on devices. Using DLP ensures protection against data leaks, helping you avoid operational issues, compliance breaches, potential penalties, and damage to your reputation.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 3 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(3)}
                                    >
                                        <div className="accordion-header" id="four">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 3 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 3}
                                            >
                                               Can data loss prevention stop users from copying sensitive data to external devices?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__four"
                                            className={`accordion-collapse collapse ${activeIndexThree === 3 ? "show" : ""}`}
                                            aria-labelledby="four"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Yes, many DLP solutions have the capability to control and restrict the copying of sensitive data onto external storage devices.
                                            </div>
                                        </div>
                                    </div>   
                                    <div className={`accordion-item ${activeIndexThree === 4 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(4)}
                                    >
                                        <div className="accordion-header" id="five">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 4 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 4}
                                            >
                                            How do I choose the right data loss prevention solution for my business?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__five"
                                            className={`accordion-collapse collapse ${activeIndexThree === 4 ? "show" : ""}`}
                                            aria-labelledby="five"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            When choosing a DLP solution, consider factors like your company’s size, the nature of the data you handle, regulatory requirements, cost, ease of use, and its compatibility with your existing IT infrastructure.
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataLossFaqThree