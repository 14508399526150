import React from 'react'

function RansomewareFeatureOne() {
    return (
        <>
            <div className="rts-hosting-feature bg-white section__padding pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="rts-section h-gaurdz-heading text-center">
                        <h2
                            className="rts-section__title "
                            data-sal="slide-down"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            Guardz Multilayered
                            Ransomware <br/>Protection
                        </h2>
                    </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6">
                            <div className="hosting-feature wordpress">
                                <h2
                                    className="hosting-feature__title hhosting-title"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Active Risk Prevention
                                </h2>
                                <p
                                    className="hosting-feature__desc hhosting-desc"
                                    data-sal="slide-down"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    The Guardz external risk monitoring offers an essential component to protect against ransomware attacks by conducting an automatic external surface scan. By assessing a business’s digital footprint, the solution identifies exposed services, and vulnerable software.
                                    <br/><br/> Guardz ensures that employees’ and clients’ digital assets remain secure from targeted malware attacks that take advantage of external vulnerabilities while preventing known CVEs are key vectors for cybercriminal exploitation.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 col-xl-6"
                            data-sal="slide-left"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            <div className="hosting-feature-image">
                                <div className="hosting-feature-image__image2 wordpress">
                                    <div className="h-img-two text-center">
                                        <img
                                            src="https://addicnet.com/wp-content/uploads/2024/06/Secure-All-Inbound-Emails.jpg"
                                            alt=""
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RansomewareFeatureOne