import React, { useState } from 'react'

function RansomewareFaqTwo() {
    const [activeIndexTwo, setActiveIndexTwo] = useState(0); // Default to the first accordion item
  
    const handleToggleTwo = (index) => {
      setActiveIndexTwo((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            <div className="rts-hosting-faq section__padding pt-5 pb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        
                        <div className="col-md-6">
                            <div className='faqs-img-two'>
                                <img src="https://addicnet.com/wp-content/uploads/2024/06/3.-Analyze.png" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rts-section h-gaurdz-heading">
                                <h2
                                    className="rts-section__title mb-0"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Guardz Ransomware 
                                    Protection in Action
                                </h2>
                            </div>
                            <div
                                className="rts-faq__accordion"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                <div className="accordion accordion-flush" id="rts-accordion">
                                    <div className={`accordion-item ${activeIndexTwo === 0 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(0)}>
                                        <div className="accordion-header" id="one">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 0 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 0}
                                              >
                                                Protection in Action
                                            </h4>
                                        </div>
                                        <div
                                           id="item__one"
                                           className={`accordion-collapse collapse ${activeIndexTwo === 0 ? "show" : ""}`}
                                           aria-labelledby="one"
                                           data-bs-parent="#rts-accordion"
                                         
                                        >
                                            <div className="accordion-body">
                                            Guardz conducts external scans to identify exposed vulnerabilities and outdated operating systems, enabling immediate remediation & strengthening of the perimeter.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexTwo === 1 ? "active" : ""}`}
                                    onClick={() => handleToggleTwo(1)}>
                                        <div className="accordion-header" id="two">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 1 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 1}
                                            >
                                               Device Protection
                                            </h4>
                                        </div>
                                        <div
                                            id="item__two"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 1 ? "show" : ""}`}
                                            aria-labelledby="two"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Guardz promptly monitors all of the Defender and Xprotect antivirus engines and detects malicious activity in real-time, ensures a secure device posture and identifies ransomware and empowers MSPs admins to take quick action and remediate the threats in an automated fashion.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexTwo === 2 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(2)}
                                    >
                                        <div className="accordion-header" id="three">
                                            <h4
                                                 className={`accordion-button ${activeIndexTwo === 2 ? "" : "collapsed"}`}
                                                 aria-expanded={activeIndexTwo === 2}
                                            >
                                                Early Detection & Investigation
                                            </h4>
                                        </div>
                                        <div
                                            id="item__three"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 2 ? "show" : ""}`}
                                            aria-labelledby="three"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Guardz provides advanced security capabilities on two fronts. It preemptively detects ransomware by creating bait files, alerting administrators before attacks escalate. Additionally, it empowers MSPs with snapshots of processes & auto-runs, enabling real-time monitoring of device activity.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexTwo === 3 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(3)}
                                    >
                                        <div className="accordion-header" id="four">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 3 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 3}
                                            >
                                               Device Isolation & Threat Quarantine
                                            </h4>
                                        </div>
                                        <div
                                            id="item__four"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 3 ? "show" : ""}`}
                                            aria-labelledby="four"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Guardz offers dynamic protection, swiftly responding to ongoing attacks by isolating compromised devices and disconnecting them from networks. Additionally, it proactively blocks or quarantines risky emails with malicious attachments, fortifying your defenses against potential mailbox infiltration.
                                            </div>
                                        </div>
                                    </div>    
                                    <div className={`accordion-item ${activeIndexTwo === 4 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(4)}
                                    >
                                        <div className="accordion-header" id="five">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 4 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 4}
                                            >
                                               Active Awareness Training
                                            </h4>
                                        </div>
                                        <div
                                            id="item__five"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 4 ? "show" : ""}`}
                                            aria-labelledby="five"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Employees are the first line of defense so training them to recognize and detect the next attack, fosters a culture of caution and vigilance.
                                            </div>
                                        </div>
                                    </div>  
                                    <div className={`accordion-item ${activeIndexTwo === 5 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(5)}
                                    >
                                        <div className="accordion-header" id="six">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 5 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 5}
                                            >
                                               Actionable Real-Time Alerts
                                            </h4>
                                        </div>
                                        <div
                                            id="item__six"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 5 ? "show" : ""}`}
                                            aria-labelledby="six"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Guardz monitors internet browsing to detect malicious sites that can lead to ransomware download and alerts system admins in real time
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RansomewareFaqTwo