import React from 'react'

function HomeElevenWhyChoose({ className }) {
    return (
        <>
            <div className={`rts-why-choose section__padding ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="rts-section section-style-two">
                            <div className="rts-section__two">
                                <h2
                                    className="title mb-0 sal-animate"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Why Chooses Hostie
                                </h2>
                            </div>
                            <div className="rts-section-description">
                                <p
                                    className="description w-400 sal-animate"
                                    data-sal="slide-left"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    Own your virtual presence with the power of Hostie with 1.5 million
                                    websites already under our care,
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* why choose feature */}
                    <div className="row g-30">
                        {/* single feature */}
                        <div
                            className="col-lg-3 col-sm-6 sal-animate"
                            data-sal="slide-down"
                            data-sal-delay={300}
                            data-sal-duration={800}
                        >
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M37.6562 19.1596V14.4438C37.6562 12.1269 35.8127 10.2335 33.5156 10.1509V7.25626C33.5156 5.74852 32.289 4.52188 30.7812 4.52188H23.2533L22.2812 1.81977C22.0333 1.13071 21.5316 0.580087 20.8684 0.269384C20.2053 -0.0413977 19.4611 -0.0745227 18.773 0.175946L5.1125 5.1479C4.42516 5.39806 3.8768 5.9011 3.56836 6.56438C3.25992 7.22766 3.22859 7.97118 3.48023 8.65806L4.02906 10.1561C1.78414 10.2949 0 12.1645 0 14.4438V35.6938C0 38.0631 1.92758 39.9906 4.29688 39.9906H33.3594C35.7287 39.9906 37.6562 38.0631 37.6562 35.6938V30.8216C38.9795 30.6315 40 29.4907 40 28.1156V21.8656C40 20.4906 38.9795 19.3498 37.6562 19.1596ZM37.6562 28.1156C37.6562 28.331 37.481 28.5063 37.2656 28.5063H31.0156C29.0771 28.5063 27.5 26.9291 27.5 24.9906C27.5 23.0521 29.0771 21.475 31.0156 21.475H37.2656C37.481 21.475 37.6562 21.6502 37.6562 21.8656V28.1156ZM5.68094 7.85181C5.63242 7.71938 5.66797 7.60759 5.69352 7.55266C5.71906 7.49782 5.78164 7.39852 5.91414 7.35032L19.5746 2.37837C19.7073 2.33016 19.8191 2.36602 19.874 2.39173C19.9289 2.41743 20.028 2.4804 20.0758 2.61321L20.7625 4.52196H20.2344C18.7266 4.52196 17.5 5.7486 17.5 7.25634V10.147H6.5218L5.68094 7.85181ZM31.1719 7.25626V10.1469H19.8438V7.25626C19.8438 7.04087 20.019 6.86563 20.2344 6.86563H30.7812C30.9966 6.86563 31.1719 7.04087 31.1719 7.25626ZM33.3594 37.6469H4.29688C3.21992 37.6469 2.34375 36.7707 2.34375 35.6938V14.4438C2.34375 13.3668 3.21992 12.4906 4.29688 12.4906H33.3594C34.4363 12.4906 35.3125 13.3668 35.3125 14.4438V19.1313H31.0156C27.7848 19.1313 25.1562 21.7598 25.1562 24.9906C25.1562 28.2215 27.7848 30.85 31.0156 30.85H35.3125V35.6938C35.3125 36.7707 34.4363 37.6469 33.3594 37.6469Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">
                                        Money-Back Guaranteed
                                    </h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* single feature end */}
                        {/* single feature */}
                        <div
                            className="col-lg-3 col-sm-6 sal-animate"
                            data-sal="slide-down"
                            data-sal-delay={400}
                            data-sal-duration={800}
                        >
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.8333 0C30.787 0 39.6667 8.87969 39.6667 19.8333C39.6667 30.787 30.787 39.6667 19.8333 39.6667C8.87969 39.6667 0 30.787 0 19.8333C0 8.87969 8.87969 0 19.8333 0ZM19.8333 2.08772C10.0327 2.08772 2.08772 10.0327 2.08772 19.8333C2.08772 29.634 10.0327 37.579 19.8333 37.579C29.634 37.579 37.579 29.634 37.579 19.8333C37.579 10.0327 29.634 2.08772 19.8333 2.08772ZM31.0101 8.65662C31.3864 9.03291 31.4542 9.71168 31.0101 10.1329L23.4307 17.7122C23.798 18.3339 24.0088 19.059 24.0088 19.8333C24.0088 22.1394 22.1394 24.0088 19.8333 24.0088C17.5273 24.0088 15.6579 22.1394 15.6579 19.8333C15.6579 17.5273 17.5273 15.6579 19.8333 15.6579C20.6077 15.6579 21.3328 15.8687 21.9545 16.236L29.5338 8.65662C29.9415 8.24897 30.6024 8.24897 31.0101 8.65662ZM19.8333 17.7456C18.6803 17.7456 17.7456 18.6803 17.7456 19.8333C17.7456 20.9864 18.6803 21.9211 19.8333 21.9211C20.9864 21.9211 21.9211 20.9864 21.9211 19.8333C21.9211 18.6803 20.9864 17.7456 19.8333 17.7456ZM32.1693 14.1715C32.9788 15.9325 33.4035 17.8539 33.4035 19.8333C33.4035 20.4098 32.9362 20.8772 32.3597 20.8772C31.7831 20.8772 31.3158 20.4098 31.3158 19.8333C31.3158 18.1565 30.9568 16.5323 30.2724 15.0435C30.0316 14.5197 30.2611 13.8998 30.7849 13.659C31.3087 13.4183 31.9285 13.6477 32.1693 14.1715ZM19.8333 6.26316C21.831 6.26316 23.7696 6.6958 25.5435 7.51973C26.0664 7.76258 26.2934 8.38331 26.0506 8.90617C25.8077 9.42904 25.187 9.65604 24.6641 9.41319C23.1642 8.71657 21.5257 8.35088 19.8333 8.35088C13.4918 8.35088 8.35088 13.4918 8.35088 19.8333C8.35088 20.4098 7.88353 20.8772 7.30702 20.8772C6.73051 20.8772 6.26316 20.4098 6.26316 19.8333C6.26316 12.3387 12.3387 6.26316 19.8333 6.26316Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">Fast &amp; Reliable</h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* single feature end */}
                        {/* single feature */}
                        <div
                            className="col-lg-3 col-sm-6 sal-animate"
                            data-sal="slide-down"
                            data-sal-delay={500}
                            data-sal-duration={800}
                        >
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M3.77599 0.159466C3.77701 0.159822 3.77805 0.16018 3.77907 0.160536L38.0902 12.0366C38.0909 12.0369 38.0917 12.0373 38.0925 12.0374C40.7087 12.9357 40.5929 16.6231 37.9963 17.4302L22.4859 22.2151C22.4698 22.2201 22.4538 22.2246 22.4377 22.2288C22.3866 22.2425 22.3399 22.2689 22.3023 22.3059C22.2647 22.3428 22.2373 22.3889 22.2228 22.4397L22.2152 22.4651L17.4302 37.9963C16.6231 40.5929 12.9357 40.7087 12.0374 38.0925C12.0373 38.0917 12.0369 38.0909 12.0366 38.0902L0.160536 3.77907C0.16018 3.77805 0.159822 3.77701 0.159466 3.77599C-0.0169322 3.27222 -0.0476967 2.72892 0.0707236 2.20842C0.189387 1.68686 0.453019 1.20947 0.831244 0.831244C1.20947 0.453019 1.68686 0.189387 2.20842 0.0707236C2.72892 -0.0476967 3.27222 -0.0169322 3.77599 0.159466ZM1.30805 3.37579L2.45745 2.97794L14.3367 37.2983L14.338 37.302C14.3766 37.4147 14.4324 37.4709 14.4834 37.5043C14.5424 37.5429 14.6246 37.569 14.7195 37.567C14.8144 37.5653 14.8984 37.5359 14.9598 37.4924C15.0137 37.4543 15.0703 37.3924 15.1069 37.2751C15.1069 37.2753 15.1071 37.2748 15.1069 37.2751L19.8872 21.7597C20.0182 21.3085 20.2631 20.8985 20.5987 20.5695C20.931 20.2435 21.3408 20.0077 21.7892 19.8843L37.2743 15.1072C37.274 15.1072 37.2745 15.1071 37.2743 15.1072C37.3917 15.0706 37.4543 15.0137 37.4924 14.9598C37.5359 14.8984 37.5653 14.8144 37.567 14.7195C37.569 14.6246 37.5429 14.5424 37.5043 14.4834C37.4709 14.4324 37.4147 14.3766 37.302 14.338L37.2983 14.3367L2.97249 2.45557C2.9004 2.43024 2.82262 2.42578 2.7481 2.44273C2.6736 2.45968 2.60541 2.49735 2.55137 2.55137C2.49735 2.60541 2.45968 2.6736 2.44273 2.7481C2.42578 2.82262 2.43022 2.9004 2.45556 2.97249L1.30805 3.37579Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">Super Easy to Use</h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* single feature end */}
                        {/* single feature */}
                        <div
                            className="col-lg-3 col-sm-6 sal-animate"
                            data-sal="slide-down"
                            data-sal-delay={600}
                            data-sal-duration={800}
                        >
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.81885 20.0032C1.81836 16.5884 2.77954 13.2425 4.59233 10.3487C6.40513 7.45482 8.99624 5.12998 12.069 3.64037C15.1417 2.15076 18.5718 1.55661 21.9665 1.92594C25.3613 2.29527 28.5834 3.61316 31.2639 5.7287H28.4978C28.2582 5.73102 28.0292 5.82783 27.8606 5.99808C27.692 6.16834 27.5974 6.39827 27.5974 6.63789C27.5974 6.87751 27.692 7.10744 27.8606 7.27769C28.0292 7.44795 28.2582 7.54476 28.4978 7.54708H33.641C33.8821 7.54708 34.1134 7.45128 34.2839 7.28077C34.4544 7.11026 34.5502 6.87899 34.5502 6.63785V1.4945C34.5502 1.25336 34.4544 1.02209 34.2839 0.851576C34.1134 0.681061 33.8821 0.585267 33.641 0.585267C33.3999 0.585267 33.1686 0.681061 32.9981 0.851576C32.8276 1.02209 32.7318 1.25336 32.7318 1.4945V4.5733C28.7673 1.30248 23.6958 -0.316762 18.5694 0.051428C13.443 0.419618 8.65509 2.74699 5.1987 6.55079C1.74231 10.3546 -0.117248 15.3429 0.00573314 20.481C0.128714 25.6192 2.2248 30.5128 5.85922 34.1469C6.0297 34.3174 6.26093 34.4131 6.50204 34.4131C6.74314 34.4131 6.97437 34.3174 7.14486 34.1469C7.31535 33.9764 7.41112 33.7452 7.41112 33.504C7.41112 33.2629 7.31535 33.0317 7.14486 32.8612C5.45102 31.1766 4.10813 29.1728 3.19392 26.9657C2.27971 24.7586 1.81234 22.3921 1.81885 20.0032Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M12.0564 36.3648C11.5405 36.1139 11.0299 35.8347 10.5391 35.5355C10.3333 35.4099 10.086 35.3711 9.85167 35.4278C9.6173 35.4845 9.41505 35.632 9.28941 35.8378C9.16378 36.0436 9.12505 36.2909 9.18174 36.5253C9.23843 36.7596 9.3859 36.9619 9.59171 37.0875C10.132 37.4174 10.694 37.7242 11.2619 38.0003C11.4784 38.103 11.7267 38.116 11.9528 38.0366C12.1789 37.9571 12.3644 37.7916 12.4692 37.5761C12.5739 37.3606 12.5893 37.1124 12.512 36.8856C12.4348 36.6587 12.2711 36.4716 12.0565 36.3648H12.0564Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M36.3729 12.0735C36.4249 12.1809 36.4976 12.2771 36.5868 12.3564C36.676 12.4358 36.78 12.4968 36.8928 12.536C37.0056 12.5752 37.125 12.5918 37.2442 12.5848C37.3634 12.5778 37.48 12.5475 37.5875 12.4954C37.695 12.4434 37.7911 12.3706 37.8705 12.2814C37.9498 12.1922 38.0108 12.0883 38.05 11.9755C38.0892 11.8627 38.1058 11.7433 38.0988 11.6241C38.0919 11.5049 38.0615 11.3882 38.0094 11.2808C37.7355 10.7147 37.4292 10.1527 37.0993 9.6101C37.0373 9.50807 36.9557 9.41927 36.8594 9.34876C36.763 9.27826 36.6536 9.22742 36.5376 9.19917C36.3033 9.1421 36.0559 9.18046 35.8498 9.3058C35.6438 9.43114 35.496 9.6332 35.4389 9.86753C35.3818 10.1019 35.4202 10.3493 35.5455 10.5553C35.8454 11.0486 36.1238 11.5594 36.3729 12.0735Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M22.1599 38.0599C21.5859 38.1281 21.0052 38.1687 20.4334 38.1824C20.1957 38.1901 19.9705 38.2906 19.806 38.4624C19.6416 38.6343 19.551 38.8637 19.5538 39.1015C19.5565 39.3394 19.6524 39.5666 19.8208 39.7346C19.9892 39.9026 20.2167 39.9978 20.4546 40H20.4763C21.1049 39.9853 21.7428 39.9403 22.3733 39.8659C22.4919 39.852 22.6067 39.8149 22.711 39.7567C22.8154 39.6984 22.9072 39.6202 22.9813 39.5264C23.0553 39.4326 23.1102 39.3252 23.1427 39.2102C23.1752 39.0952 23.1847 38.9749 23.1707 38.8563C23.1567 38.7376 23.1194 38.6229 23.0611 38.5186C23.0027 38.4144 22.9243 38.3226 22.8305 38.2487C22.7366 38.1747 22.6291 38.12 22.5141 38.0876C22.3991 38.0552 22.2788 38.0458 22.1602 38.06L22.1599 38.0599Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M16.9857 37.9365C16.4201 37.8418 15.8514 37.7189 15.2956 37.5699C15.1801 37.5388 15.0597 37.5307 14.9411 37.5462C14.8225 37.5617 14.7081 37.6004 14.6045 37.6602C14.5009 37.7199 14.4101 37.7995 14.3373 37.8944C14.2645 37.9893 14.2112 38.0976 14.1803 38.2131C14.1494 38.3286 14.1416 38.4491 14.1573 38.5677C14.1731 38.6862 14.212 38.8005 14.272 38.904C14.332 39.0074 14.4118 39.0981 14.5068 39.1707C14.6018 39.2433 14.7102 39.2964 14.8258 39.327C15.4377 39.4904 16.0636 39.6259 16.6865 39.7297C16.8045 39.75 16.9254 39.7467 17.0422 39.7201C17.1589 39.6936 17.2693 39.6442 17.3669 39.5748C17.4646 39.5055 17.5475 39.4175 17.6111 39.316C17.6746 39.2145 17.7174 39.1014 17.7371 38.9833C17.7569 38.8651 17.753 38.7443 17.7259 38.6276C17.6987 38.511 17.6488 38.4009 17.579 38.3036C17.5092 38.2063 17.4208 38.1237 17.319 38.0607C17.2172 37.9977 17.1039 37.9553 16.9857 37.9362V37.9365Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M39.1133 19.5648C38.8723 19.5589 38.6388 19.649 38.4641 19.8152C38.2894 19.9815 38.188 20.2103 38.1819 20.4513C38.1673 21.0263 38.1255 21.607 38.0572 22.1778C38.0287 22.4173 38.0964 22.6583 38.2455 22.8478C38.3946 23.0374 38.6128 23.16 38.8523 23.1887C38.8885 23.1928 38.925 23.1949 38.9614 23.195C39.1837 23.1946 39.3981 23.1129 39.5642 22.9653C39.7304 22.8177 39.8367 22.6144 39.8632 22.3937C39.9378 21.7664 39.9839 21.1285 39.9996 20.4967C40.0026 20.3773 39.9821 20.2585 39.9392 20.147C39.8963 20.0355 39.8319 19.9336 39.7496 19.8471C39.6673 19.7606 39.5687 19.6911 39.4595 19.6427C39.3504 19.5942 39.2327 19.5678 39.1133 19.5648Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M36.3224 29.9645C36.1229 29.8293 35.8778 29.7787 35.641 29.8239C35.4043 29.8691 35.1951 30.0063 35.0594 30.2056C34.7362 30.681 34.3868 31.1467 34.0201 31.5893C33.9422 31.681 33.8832 31.7873 33.8467 31.902C33.8101 32.0167 33.7967 32.1375 33.8072 32.2574C33.8177 32.3773 33.8519 32.4939 33.9078 32.6005C33.9638 32.7071 34.0403 32.8015 34.133 32.8782C34.2257 32.955 34.3328 33.0126 34.4479 33.0476C34.5631 33.0827 34.684 33.0945 34.8038 33.0825C34.9235 33.0704 35.0397 33.0347 35.1456 32.9774C35.2514 32.92 35.3448 32.8423 35.4203 32.7486C35.8234 32.262 36.2084 31.7502 36.5635 31.2272C36.6306 31.1284 36.6776 31.0175 36.7018 30.9006C36.7259 30.7837 36.7269 30.6632 36.7045 30.5459C36.6821 30.4287 36.6368 30.317 36.5713 30.2172C36.5057 30.1175 36.4212 30.0316 36.3224 29.9645Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M37.5747 15.3125C37.7221 15.8666 37.8446 16.4347 37.9392 17.003C37.9744 17.2153 38.0838 17.4082 38.2478 17.5474C38.4118 17.6867 38.6199 17.7632 38.8351 17.7635C38.8852 17.7634 38.9352 17.7592 38.9847 17.7511C39.1025 17.7315 39.2153 17.689 39.3167 17.6258C39.418 17.5627 39.506 17.4802 39.5754 17.3831C39.6449 17.2859 39.6945 17.1761 39.7215 17.0598C39.7485 16.9434 39.7523 16.8229 39.7327 16.7051C39.6293 16.0801 39.4941 15.4539 39.3321 14.8445C39.2688 14.613 39.1164 14.4159 38.9083 14.2963C38.7001 14.1766 38.4531 14.1441 38.2212 14.2059C37.9892 14.2677 37.791 14.4187 37.67 14.626C37.5489 14.8333 37.5148 15.0801 37.5749 15.3125H37.5747Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M31.5729 34.0324C31.1284 34.3999 30.6628 34.7493 30.189 35.0703C30.0902 35.1373 30.0055 35.2231 29.9399 35.3228C29.8742 35.4225 29.8288 35.5342 29.8063 35.6514C29.7838 35.7687 29.7846 35.8892 29.8087 36.0061C29.8328 36.1231 29.8797 36.2341 29.9466 36.3329C30.0136 36.4317 30.0994 36.5164 30.1991 36.5821C30.2988 36.6477 30.4105 36.6931 30.5277 36.7156C30.645 36.7381 30.7655 36.7373 30.8824 36.7132C30.9994 36.6892 31.1104 36.6423 31.2092 36.5753C31.7305 36.2223 32.2423 35.8383 32.7316 35.4343C32.8243 35.3584 32.901 35.265 32.9575 35.1593C33.0139 35.0537 33.049 34.938 33.0606 34.8187C33.0722 34.6995 33.0601 34.5792 33.0251 34.4647C32.99 34.3501 32.9327 34.2436 32.8564 34.1513C32.7801 34.059 32.6863 33.9827 32.5804 33.9267C32.4745 33.8707 32.3586 33.8362 32.2393 33.8252C32.1201 33.8141 31.9998 33.8267 31.8854 33.8623C31.7711 33.8979 31.6648 33.9557 31.5729 34.0324Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M38.4642 24.9652C38.2345 24.8922 37.9853 24.9134 37.7712 25.024C37.557 25.1346 37.3955 25.3256 37.3221 25.5551C37.1481 26.1006 36.9447 26.6455 36.7179 27.1751C36.6683 27.2852 36.6411 27.4041 36.638 27.5248C36.6349 27.6456 36.6558 27.7657 36.6996 27.8782C36.7434 27.9908 36.8092 28.0934 36.8931 28.1803C36.9771 28.2671 37.0775 28.3363 37.1884 28.3839C37.2994 28.4315 37.4188 28.4565 37.5395 28.4575C37.6603 28.4585 37.78 28.4353 37.8918 28.3895C38.0035 28.3437 38.105 28.2761 38.1903 28.1906C38.2756 28.1051 38.343 28.0035 38.3886 27.8916C38.6389 27.3087 38.8626 26.708 39.054 26.1074C39.0904 25.9937 39.1039 25.8739 39.094 25.755C39.084 25.636 39.0507 25.5201 38.9959 25.4141C38.9411 25.308 38.866 25.2137 38.7748 25.1367C38.6835 25.0597 38.578 25.0014 38.4642 24.9652Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M27.1577 36.7253C26.6333 36.95 26.0882 37.1528 25.5373 37.3286C25.4235 37.3649 25.318 37.4232 25.2268 37.5002C25.1356 37.5772 25.0605 37.6715 25.0057 37.7775C24.9509 37.8836 24.9175 37.9994 24.9075 38.1184C24.8975 38.2374 24.911 38.3571 24.9472 38.4709C24.9835 38.5846 25.0418 38.6901 25.1188 38.7813C25.1959 38.8725 25.2901 38.9477 25.3962 39.0025C25.5023 39.0572 25.6181 39.0906 25.737 39.1006C25.856 39.1107 25.9758 39.0972 26.0895 39.0609C26.6956 38.8678 27.2957 38.6445 27.8733 38.3972C28.095 38.3023 28.2699 38.1231 28.3596 37.8992C28.4492 37.6753 28.4462 37.425 28.3513 37.2033C28.2563 36.9815 28.0772 36.8066 27.8533 36.717C27.6294 36.6274 27.379 36.6303 27.1573 36.7253H27.1577Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M12.3134 18.1456C12.3134 16.6556 11.3331 15.4616 9.81699 15.1035C8.9839 14.9062 8.10853 14.9971 7.33376 15.3614C6.55899 15.7257 5.93057 16.3418 5.55108 17.1092C5.49768 17.216 5.46583 17.3323 5.45737 17.4514C5.4489 17.5706 5.46398 17.6902 5.50174 17.8035C5.53951 17.9167 5.59921 18.0215 5.67745 18.1117C5.7557 18.2019 5.85094 18.2758 5.95775 18.3292C6.06456 18.3826 6.18084 18.4145 6.29996 18.423C6.41907 18.4314 6.53869 18.4163 6.65198 18.3786C6.76527 18.3408 6.87001 18.2811 6.96022 18.2029C7.05044 18.1246 7.12436 18.0294 7.17776 17.9226C7.37189 17.5197 7.69843 17.1957 8.10277 17.0047C8.50712 16.8137 8.96478 16.7673 9.39923 16.8733C9.65631 16.9339 10.4949 17.2051 10.4949 18.1456C10.4949 19.0939 9.39966 19.8253 8.24008 20.5998C6.93449 21.4713 5.45519 22.4591 5.45519 24.0946C5.45519 24.3357 5.55098 24.567 5.7215 24.7375C5.89201 24.908 6.12328 25.0038 6.36442 25.0038H11.4041C11.6453 25.0038 11.8765 24.908 12.047 24.7375C12.2176 24.567 12.3134 24.3357 12.3134 24.0946C12.3134 23.8534 12.2176 23.6222 12.047 23.4516C11.8765 23.2811 11.6453 23.1853 11.4041 23.1853H7.80196C8.1828 22.8244 8.72134 22.4648 9.24964 22.1119C10.686 21.153 12.3134 20.0662 12.3134 18.1456Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M20.9118 21.1396C20.9118 20.8984 20.816 20.6672 20.6455 20.4967C20.475 20.3262 20.2437 20.2304 20.0026 20.2304H19.2638V15.4002C19.2638 15.2119 19.2053 15.0283 19.0964 14.8747C18.9876 14.721 18.8337 14.605 18.656 14.5425C18.4784 14.4801 18.2857 14.4743 18.1047 14.5261C17.9236 14.5778 17.7631 14.6845 17.6453 14.8314L13.0424 20.5709C12.9353 20.7046 12.8681 20.8658 12.8487 21.036C12.8292 21.2062 12.8582 21.3785 12.9323 21.5329C13.0065 21.6874 13.1227 21.8178 13.2677 21.909C13.4127 22.0003 13.5805 22.0487 13.7518 22.0488H17.4455V24.6059C17.4443 24.7261 17.467 24.8453 17.5121 24.9566C17.5573 25.0679 17.6241 25.1692 17.7086 25.2546C17.7932 25.3399 17.8938 25.4077 18.0047 25.4539C18.1156 25.5002 18.2345 25.524 18.3546 25.524C18.4748 25.524 18.5937 25.5002 18.7046 25.4539C18.8155 25.4077 18.9161 25.3399 19.0007 25.2546C19.0852 25.1692 19.152 25.0679 19.1972 24.9566C19.2423 24.8453 19.265 24.7261 19.2638 24.6059V22.0488H20.0026C20.2437 22.0488 20.475 21.953 20.6455 21.7825C20.816 21.612 20.9118 21.3807 20.9118 21.1396ZM17.4455 20.2304H15.6469L17.4455 17.9871V20.2304Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M25.5659 13.9975C25.4488 13.9738 25.3282 13.9735 25.211 13.9966C25.0938 14.0196 24.9823 14.0656 24.8829 14.1319C24.7835 14.1981 24.6981 14.2834 24.6317 14.3827C24.5653 14.482 24.5192 14.5935 24.496 14.7107L22.4503 24.9392C22.4269 25.0563 22.4267 25.1768 22.4499 25.2939C22.473 25.4111 22.519 25.5225 22.5852 25.6219C22.6514 25.7212 22.7365 25.8065 22.8357 25.873C22.9349 25.9395 23.0462 25.9857 23.1633 26.0092C23.2804 26.0326 23.4009 26.0328 23.518 26.0096C23.6351 25.9865 23.7466 25.9405 23.8459 25.8743C23.9453 25.8081 24.0306 25.7229 24.0971 25.6238C24.1635 25.5246 24.2098 25.4133 24.2332 25.2962L26.2789 15.0676C26.3025 14.9505 26.3027 14.83 26.2796 14.7128C26.2564 14.5956 26.2105 14.4842 26.1442 14.3848C26.078 14.2854 25.9929 14.2 25.8936 14.1336C25.7944 14.0671 25.683 14.0209 25.5659 13.9975Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M30.2315 25.0042C30.411 25.0041 30.5865 24.9509 30.7359 24.8512C30.8853 24.7516 31.0019 24.6101 31.071 24.4443L34.4805 16.2615C34.5381 16.1233 34.5608 15.973 34.5463 15.824C34.5319 15.6749 34.4809 15.5318 34.3979 15.4072C34.3148 15.2826 34.2022 15.1805 34.0702 15.1099C33.9382 15.0393 33.7907 15.0024 33.641 15.0024H29.0381C28.797 15.0024 28.5657 15.0982 28.3952 15.2688C28.2247 15.4393 28.1289 15.6705 28.1289 15.9117C28.1289 16.1528 28.2247 16.3841 28.3952 16.5546C28.5657 16.7251 28.797 16.8209 29.0381 16.8209H32.2772L29.3924 23.745C29.3351 23.8832 29.3127 24.0334 29.3272 24.1823C29.3418 24.3312 29.3928 24.4742 29.4757 24.5987C29.5587 24.7232 29.671 24.8253 29.8028 24.896C29.9347 24.9667 30.0819 25.0039 30.2315 25.0042Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">24/7 Expert Support</h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* single feature end */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeElevenWhyChoose