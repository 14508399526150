import React, { useState } from 'react'

function SecurityFaqthree() {
    const [activeIndexThree, setActiveIndexThree] = useState(0); // Default to the first accordion item
  
    const handleToggleThree = (index) => {
      setActiveIndexThree((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            <div className="rts-hosting-faq section__padding pb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="rts-section text-center">
                            <h2
                                className="rts-section__title mb-0"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                Looking for Answers? <br/>
                                You're in the Right Place
                            </h2>
                        </div>
                        <div className="col-lg-8">
                            <div
                                className="rts-faq__accordion"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                <div className="accordion accordion-flush" id="rts-accordion">
                                    <div className={`accordion-item ${activeIndexThree === 0 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(0)}>
                                        <div className="accordion-header" id="one">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 0 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 0}
                                              >
                                                How can I identify a phishing email?
                                            </h4>
                                        </div>
                                        <div
                                           id="item__one"
                                           className={`accordion-collapse collapse ${activeIndexThree === 0 ? "show" : ""}`}
                                           aria-labelledby="one"
                                           data-bs-parent="#rts-accordion"
                                         
                                        >
                                            <div className="accordion-body">
                                            Poor grammar or spelling errors, urgent or threatening language, requests for personal or financial information, unusual or mismatched email addresses or URLs, suspicious attachments or links, unexpected or unsolicited messages from unfamiliar sources.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 1 ? "active" : ""}`}
                                    onClick={() => handleToggleThree(1)}>
                                        <div className="accordion-header" id="two">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 1 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 1}
                                            >
                                                Can I rely on email filters to protect me from phishing?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__two"
                                            className={`accordion-collapse collapse ${activeIndexThree === 1 ? "show" : ""}`}
                                            aria-labelledby="two"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            While email filters can help catch some phishing emails, they are not foolproof in phishing protection. Attackers constantly adapt their techniques, making it challenging for filters to catch all phishing attempts. Therefore, it is essential to remain vigilant and not solely rely on filters for phishing protection.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 2 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(2)}
                                    >
                                        <div className="accordion-header" id="three">
                                            <h4
                                                 className={`accordion-button ${activeIndexThree === 2 ? "" : "collapsed"}`}
                                                 aria-expanded={activeIndexThree === 2}
                                            >
                                               What should I do if I receive a phishing email?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__three"
                                            className={`accordion-collapse collapse ${activeIndexThree === 2 ? "show" : ""}`}
                                            aria-labelledby="three"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            If you receive a phishing email, take the following steps to protect against phishing attacks: Do not click on any links or download attachments to avoid falling victim to phishing attempts, do not reply or provide any personal information as it can be used against you, report the email as spam or phishing to your email provider to aid in phishing protection efforts, delete the email from your inbox to prevent accidental clicks on phishing links, If the email appears to be from a legitimate organization, contact them directly using official contact information to verify its authenticity.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexThree === 3 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(3)}
                                    >
                                        <div className="accordion-header" id="four">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 3 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 3}
                                            >
                                               Can training programs help organizations strengthen their phishing protection?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__four"
                                            className={`accordion-collapse collapse ${activeIndexThree === 3 ? "show" : ""}`}
                                            aria-labelledby="four"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Addicnet offers cyber awareness training which includes Phishing Simulations , customized and schedules campaigns with the goal of educating employees and clients on how to be more vigilant and prevent falling victim to phishing attacks.
                                            </div>
                                        </div>
                                    </div>   
                                    <div className={`accordion-item ${activeIndexThree === 4 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(4)}
                                    >
                                        <div className="accordion-header" id="five">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 4 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 4}
                                            >
                                            What should I do if I have responded to a phishing email?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__five"
                                            className={`accordion-collapse collapse ${activeIndexThree === 4 ? "show" : ""}`}
                                            aria-labelledby="five"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            If you’ve responded to a phishing email, take immediate action. Change your passwords with strong ones, scan your device with anti-phishing software, monitor accounts for unauthorized activity, report the incident, educate yourself on phishing techniques, and enable two-factor authentication (2FA) for added protection.
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`accordion-item ${activeIndexThree === 5 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(5)}
                                    >
                                        <div className="accordion-header" id="six">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 5 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 5}
                                            >
                                            What is a phishing scam?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__six"
                                            className={`accordion-collapse collapse ${activeIndexThree === 5 ? "show" : ""}`}
                                            aria-labelledby="six"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            A phishing scam is a fraudulent attempt by cybercriminals to obtain sensitive information such as usernames, passwords, and credit card details by disguising themselves as a trustworthy entity in an electronic communication, usually via email or text message. The attackers aim to trick the recipients into clicking on malicious links or providing personal information, which can then be used for identity theft or other malicious activities.
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`accordion-item ${activeIndexThree === 6 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(6)}
                                    >
                                        <div className="accordion-header" id="seven">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 6 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 6}
                                            >
                                            What is whaling in cybersecurity?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__seven"
                                            className={`accordion-collapse collapse ${activeIndexThree === 6 ? "show" : ""}`}
                                            aria-labelledby="seven"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Whaling in cybersecurity is a highly targeted phishing attack aimed specifically at high-level executives, CEOs, or other senior management within an organization. Cybercriminals use social engineering techniques, detailed research about the target, and carefully crafted emails or messages to impersonate trusted individuals or organizations, aiming to manipulate the executive into revealing sensitive information or transferring funds to the attacker’s account.
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`accordion-item ${activeIndexThree === 7 ? "active" : ""}`}
                                        onClick={() => handleToggleThree(7)}
                                    >
                                        <div className="accordion-header" id="eight">
                                            <h4
                                                className={`accordion-button ${activeIndexThree === 7 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexThree === 7}
                                            >
                                            What is a spear-phishing attack?
                                            </h4>
                                        </div>
                                        <div
                                            id="item__eight"
                                            className={`accordion-collapse collapse ${activeIndexThree === 7 ? "show" : ""}`}
                                            aria-labelledby="eight"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            A spear-phishing attack is a more targeted form of phishing, where cybercriminals customize their phishing emails to a specific individual or organization using personal information gathered from various sources, such as social media or previous data breaches. The goal of spear-phishing is to increase the chances of the target engaging with the malicious content, such as downloading an attachment or clicking on a link that leads to a fraudulent website.
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecurityFaqthree