import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";


function HomeTenPricing() {
    // Existing pricing tabs state
    const [activeTab, setActiveTab] = useState("monthly");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    // Show more features toggle state
    const [showMoreFeatures, setShowMoreFeatures] = useState(false);
    const handleToggle = () => {
        setShowMoreFeatures(!showMoreFeatures);
    };

    // New tab functionality state
    const [activeNewTab, setActiveNewTab] = useState(0);
    const tabs = [
        { name: "Cloud", image: "https://img.freepik.com/free-photo/online-cloud-data-storage-concept-cloudscape-digital-online-server-global-network-business-web-database-backup-computer-private-infrastructure-technology_90220-1345.jpg?t=st=1732415213~exp=1732418813~hmac=72a60c2e20a054ee2ec685d9a1fddd94bef665abe15f4072688e1d56f6205c66&w=740", 
        text: "Cloud",
        extraHtml: `
            <ul class= "h-ul-name">
                 <li><a href="#">DevOps</a></li>
                 <li><a href="#">Cloud Migration</a></li>
                 <li><a href="#">Well-Architected</a></li>
                 <li><a href="#">FinOps</a></li>
                 <li><a href="#">DevSecOps</a></li>
                 <li><a href="#">Platform Engineering</a></li>
                 <li><a href="#">LLMOps</a></li>
            </ul>
        `,
        },
        { name: "Hosting", image: "https://img.freepik.com/premium-photo/dark-blue-datacenter-with-servers-generative-ai_634358-355.jpg?w=900", 
            text: "Hosting",
        extraHtml: `
            <ul class= "h-ul-name">
                 <li><a href="#">Encrypted hosting </a></li>
                 <li><a href="#">E-commerce</a></li>
                 <li><a href="#">WordPress</a></li>
                 <li><a href="#">WooCommerce</a></li>
            </ul>
        `,
         },
        { name: "Security", image: "https://img.freepik.com/premium-vector/cyber-security-levels-knob-button-wireframe-hand-turning-secure-test-knob-maximum-position_127544-510.jpg?w=1060", 
            text: "Security",
        extraHtml: `
            <ul class= "h-ul-name">
                 <li><a href="#">phishing protection</a></li>
                 <li><a href="#">Data loss prevention</a></li>
                 <li><a href="#">Ransomware protection</a></li>
                 <li><a href="#">Email security</a></li>
                 <li><a href="#">Managed SOC</a></li>
            </ul>
        `,
         },
        { name: "Data cloud storage", image: "https://img.freepik.com/free-photo/cyber-security-concept-digital-art_23-2151637770.jpg?t=st=1732415483~exp=1732419083~hmac=fa55531f462dffbf4f8cd0ae8433f4ad0ff74524b0d60db0cc923aa35e570fca&w=1060", text: "Data cloud storage",
            extraHtml: `
            <ul class= "h-ul-name">
                 <li><a href="#">Encrypted Cloud Storage</a></li>
                 <li><a href="#">Backup</a></li>
                 <li><a href="#">File sync</a></li>
                 <li><a href="#">Share on-premise</a></li>
            </ul>
        `,
         },
        { name: "Talent", image: "https://img.freepik.com/free-photo/futuristic-business-scene-with-ultra-modern-ambiance_23-2151003791.jpg?t=st=1732415532~exp=1732419132~hmac=33107117cb20cdd86b33b4fa1d2ba3c293b4a8f3ed9d36a2dc4fd44f6f323611&w=900", text: "Talent",
            extraHtml: `
            <ul class= "h-ul-name">
                 <li><span>
                    Get a cloud or cybersecurity professional hand-picked for your needs and integrated with your team for a month or more.
                 </span></li>
            </ul>
        `,
         },
        { name: "Emails", image: "https://img.freepik.com/free-photo/empty-business-card-laptop_23-2148500523.jpg?t=st=1732415627~exp=1732419227~hmac=bd21e6c04e64123c2cc1b3156fc60d785cea0c27f1e7553e689c2d656f0c9b58&w=900", text: "Emails",
            extraHtml: `
            <ul class= "h-ul-name">
                 <li><a href="#">Professional Email</a></li>
                 <li><a href="#">Google workspace</a></li>
                 <li><a href="#">Protonmail</a></li>
                 <li><a href="#">Titan mail</a></li>
            </ul>
        `,
        },
        { name: "Labs", image: "https://img.freepik.com/free-photo/designers-using-3d-printer_23-2151037102.jpg?t=st=1732415713~exp=1732419313~hmac=56c79279179aaa3a235005b2796a2682ba91382ed2256028a1d25c0dd1c7c1a1&w=900", text: "Labs",
            extraHtml: `
            <ul class= "h-ul-name">
                 <li><a href="#">Digital commerce</a></li>
                 <li><a href="#">Custom development lab</a></li>
            </ul>
        `,
         },
    ];
    return (
        <>
            <div className="rts-pricing-plan alice__blue section__padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="rts-section w-640 text-center">
                            <h2
                                className="rts-section__title"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                               WHAT WE DO IN SOLVING BUSINESS CHALLENGES.
                            </h2>
                            <p
                                className="rts-section__description"
                                data-sal="slide-down"
                                data-sal-delay={200}
                                data-sal-duration={800}
                            >
                                Unlock the full potential of your Cloud & security journey for optimal outcomes
                                One platform, many innovative technology solutions, and best outcomes..
                            </p>
                        </div>
                    </div>

                 {/* New Tab Functionality */}
            <div className="container h-container">
                {/* Desktop View */}
                <div className="d-none d-lg-flex row">
                    {/* Tabs */}
                    <div className="col-lg-3 mt-5">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`h-tab py-3 custom-tab ${
                                    activeNewTab === index ? "h-active" : ""
                                }`}
                                onClick={() => setActiveNewTab(index)}
                            >
                                {tab.name}
                                {activeNewTab === index && <span className="h-icon"><i class="fa-solid fa-angle-right"></i></span>}
                            </div>
                        ))}
                    </div>
                    {/* Image */}
                    <div className="col-lg-9  justify-content-center align-items-center">
                        <div className='himg-content'>
                            <div className='himg-one'>
                                <img
                                src={tabs[activeNewTab].image}
                                alt={`Image for ${tabs[activeNewTab].name}`}
                                className="img-fluid max-width-100"
                            />
                            </div>
                            <div className='hcontent-main'>
                            <h5 className='h-tab-title'>{tabs[activeNewTab].text}</h5>
                            <div 
                                className="extra-content"
                                dangerouslySetInnerHTML={{ __html: tabs[activeNewTab].extraHtml }}
                            ></div>
                            </div>
                        </div>
                    </div>
                    {/* Text */}
                    
                </div>

                {/* Mobile View */}
                <div className="d-block d-lg-none">
                <Swiper
                    spaceBetween={20}
                    slidesPerView={2.5} // Default value
                    breakpoints={{
                        // When the screen width is >= 576px
                        100: {
                            slidesPerView: 1,
                        },
                        440: {
                            slidesPerView: 1.5,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        // When the screen width is >= 768px
                        768: {
                            slidesPerView: 2.5,
                        },
                    }}
                >
                    {tabs.map((tab, index) => (
                        <SwiperSlide key={index}>
                            <div className="hslider-item">
                                <img
                                    src={tab.image}
                                    alt={`Image for ${tab.name}`}
                                    className="himg-slider"
                                />
                                <div className="hcontent-main">
                                    {/* Render the content for each tab dynamically */}
                                    <h5 className="h-tab-title">{tab.text}</h5>
                                    <div
                                        className="extra-content"
                                        dangerouslySetInnerHTML={{ __html: tab.extraHtml }}
                                    ></div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </div>
            </div>

                  
                </div>
            </div>

        </>
    )
}

export default HomeTenPricing