import React from 'react'

function SecurityfeatureOne() {
    return (
        <>
            <div className="rts-hosting-feature bg-white section__padding pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="rts-section h-gaurdz-heading text-center">
                        <h2
                            className="rts-section__title "
                            data-sal="slide-down"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            Guardz Multilayered
                            Phishing <br/>Protection
                        </h2>
                    </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6">
                            <div className="hosting-feature wordpress">
                                <h2
                                    className="hosting-feature__title hhosting-title"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Secure All Inbound Emails
                                </h2>
                                <p
                                    className="hosting-feature__desc hhosting-desc"
                                    data-sal="slide-down"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    Guardz streamlines email protection with its fully managed, cloud-based solution that eliminates manual setup and seamlessly integrates with Microsoft 365 and Google Workspace accounts. Leveraging advanced predictive analysis, global intelligence tools, and continuous updates, Guardz provides robust defense against multiple attack vectors.
                                    <br/><br/> High-risk emails are immediately placed into quarantine to programmatically remove the threat. Employees receive immediate warnings and recommended actions for potentially harmful emails, while IT admins can automatically flag and remove malicious messages.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 col-xl-6"
                            data-sal="slide-left"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            <div className="hosting-feature-image">
                                <div className="hosting-feature-image__image2 wordpress">
                                    <div className="h-img-two text-center">
                                        <img
                                            src="https://addicnet.com/wp-content/uploads/2024/06/Secure-All-Inbound-Emails.jpg"
                                            alt=""
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SecurityfeatureOne