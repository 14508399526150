import React, { useState } from 'react'

function SecurityFaq() {
    const [activeIndexOne, setActiveIndexOne] = useState(0); // Default to the first accordion item
  
    const handleToggleOne = (index) => {
      setActiveIndexOne((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            <div className="rts-hosting-faq section__padding pb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="rts-section text-center">
                            <h2
                                className="rts-section__title mb-0"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                The Rise of Sophisticated <br/>
                                Phishing Attacks Targeting SMEs
                            </h2>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="rts-faq__accordion"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                <div className="accordion accordion-flush" id="rts-accordion">
                                    <div className={`accordion-item ${activeIndexOne === 0 ? "active" : ""}`}
                                        onClick={() => handleToggleOne(0)}>
                                        <div className="accordion-header" id="one">
                                            <h4
                                                className={`accordion-button ${activeIndexOne === 0 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexOne === 0}
                                              >
                                                Email Imposters
                                            </h4>
                                        </div>
                                        <div
                                           id="item__one"
                                           className={`accordion-collapse collapse ${activeIndexOne === 0 ? "show" : ""}`}
                                           aria-labelledby="one"
                                           data-bs-parent="#rts-accordion"
                                         
                                        >
                                            <div className="accordion-body">
                                            Fraudulent tactics in your inbox can expose you to deceptive techniques that cybercriminals use to manipulate unsuspecting victims. Phishing involves deceiving recipients into disclosing sensitive information through seemingly legitimate emails. Spear-phishing raises the bar by crafting tailored messages based on the target’s business intel, while whaling impersonates high-level executives to deceive lower-level employees presenting the potential for financial losses.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexOne === 1 ? "active" : ""}`}
                                    onClick={() => handleToggleOne(1)}>
                                        <div className="accordion-header" id="two">
                                            <h4
                                                className={`accordion-button ${activeIndexOne === 1 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexOne === 1}
                                            >
                                                Malicious Websites
                                            </h4>
                                        </div>
                                        <div
                                            id="item__two"
                                            className={`accordion-collapse collapse ${activeIndexOne === 1 ? "show" : ""}`}
                                            aria-labelledby="two"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                                Malicious websites are a significant threat in the digital landscape, as they are specifically designed to compromise user’s devices, steal sensitive data, or exploit system vulnerabilities. Industry best practices dictates the behavior of software should be transparent and not be deceptive, exfiltrative, unpredictive, and/or difficult to remove. Malware, lurking in malicious ads, lures users to deceptive websites or automatically download malware once clicked.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexOne === 2 ? "active" : ""}`}
                                        onClick={() => handleToggleOne(2)}
                                    >
                                        <div className="accordion-header" id="three">
                                            <h4
                                                 className={`accordion-button ${activeIndexOne === 2 ? "" : "collapsed"}`}
                                                 aria-expanded={activeIndexOne === 2}
                                            >
                                                Social Engineering
                                            </h4>
                                        </div>
                                        <div
                                            id="item__three"
                                            className={`accordion-collapse collapse ${activeIndexOne === 2 ? "show" : ""}`}
                                            aria-labelledby="three"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Social engineering is a psychological method used by cybercriminals to exploit human trust and gain access to sensitive information. A common form of social engineering is phishing attacks, where attackers impersonate trusted entities via email, text, or phone calls with the intent to deceive individuals into revealing personal data.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexOne === 3 ? "active" : ""}`}
                                        onClick={() => handleToggleOne(3)}
                                    >
                                        <div className="accordion-header" id="four">
                                            <h4
                                                className={`accordion-button ${activeIndexOne === 3 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexOne === 3}
                                            >
                                               Dark Web Threats
                                            </h4>
                                        </div>
                                        <div
                                            id="item__four"
                                            className={`accordion-collapse collapse ${activeIndexOne === 3 ? "show" : ""}`}
                                            aria-labelledby="four"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                                Exposed services, such as open ports, misconfigured firewalls, and applications with vulnerabilities, enable hackers to infiltrate networks. Missing records, such as incorrect DNS records, missing SPF or DKIM records, and other configuration errors, can lead to phishing attacks, spoofing, and more, while while leaked credentials, found in data dumps and other lists on the dark web and pose a critical risk to employees and businesses if unchecked.
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='faqs-img'>
                                <img src="https://addicnet.com/wp-content/uploads/2024/06/Exploiting-Vulnerabilities-image.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecurityFaq