import React from 'react'
import Header from '../components/header/HeaderTen'
import DataLossBanner from '../components/Data-loss-prevention/DataLossBanner'
import DataLossfaq from '../components/Data-loss-prevention/DataLossFaq'
import DataLossFeature from '../components/Data-loss-prevention/DataLossFeature'
import DataLossFaqTwo from '../components/Data-loss-prevention/DataLossFaqTwo'
import DataLossFeatureOne from '../components/Data-loss-prevention/DataLossFeatureOne'
import DataLossFeatureTwo from '../components/Data-loss-prevention/DataLossFeatureTwo'
import DataLossFeatureThree from '../components/Data-loss-prevention/DataLossFeatureThree'
import DataLossFeatureFour from '../components/Data-loss-prevention/DataLossFeatureFour'
import DataLossFeatureFive from '../components/Data-loss-prevention/DataLossFeatureFive';
import DataLossFaqThree from '../components/Data-loss-prevention/DataLossFaqThree'
import HomeEightCta from '../components/home-eight/HomeEightCta'
import HomeTenFooter from '../components/footer/HomeTenFooter'

function DataLossPrevention() {
  return (
    <div className='wordpress-hosting page-template'>
        <Header />
        <DataLossBanner />
        <DataLossfaq/>
        <DataLossFeature />
        {/*<HomeFourPricing />*/}
        <DataLossFaqTwo />
        <DataLossFeatureOne/>
        <DataLossFeatureTwo/>
        <DataLossFeatureThree/>
        <DataLossFeatureFour/>
        <DataLossFeatureFive/>
        {/*<WordPressHostingPlugins/>
        <AboutTestimonials className="bg-white pb--0"/>
        <PricingFaq />*/}
        <DataLossFaqThree/>
        <HomeEightCta />
        <HomeTenFooter />
    </div>
  )
}

export default DataLossPrevention