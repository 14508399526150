import React from 'react'

function DataLossFeature() {
    return (
        <>
            <section className="rts-pricing-five inner bg-white">
                <div className="container">
                    <div className="rts-section text-center">
                        <h3 className="rts-section__title font-40 mb-0">
                            Let's Dive into the Data
                        </h3>
                    </div>
                    <div className="section-inner">
                        <div className="pricing-list">
                            <div className="row gy-30 justify-content-center">
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="pricing-wrapper">
                                        <div className="icon">
                                            <img src="assets/images/pricing/pricing-icon-01.svg" alt="" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">4.45</h4>
                                            <p className="desc text-decoration-none">
                                            Phishing emails are sent
                                            every day{" "}
                                            </p>
                                        </div>
                                        <div className="pricing-shape-area">
                                            <img
                                                src="assets/images/pricing/pricing-bg-shape.svg"
                                                alt=""
                                                className="one"
                                            />
                                            <img
                                                src="assets/images/pricing/pricing-shape-01.svg"
                                                alt=""
                                                className="two"
                                            />
                                            <img
                                                src="assets/images/pricing/pricing-shape-02.svg"
                                                alt=""
                                                className="three"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="pricing-wrapper">
                                        <div className="icon">
                                            <img src="assets/images/pricing/pricing-icon-02.svg" alt="" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">90 %</h4>
                                            <p className="desc text-decoration-none">
                                            Of data breaches occur as a
                                            result of phishing attacks
                                            </p>
                                        </div>
                                        <div className="pricing-shape-area">
                                            <img
                                                src="assets/images/pricing/pricing-bg-shape.svg"
                                                alt=""
                                                className="one"
                                            />
                                            <img
                                                src="assets/images/pricing/pricing-shape-01.svg"
                                                alt=""
                                                className="two"
                                            />
                                            <img
                                                src="assets/images/pricing/pricing-shape-02.svg"
                                                alt=""
                                                className="three"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="pricing-wrapper">
                                        <div className="icon">
                                            <img src="assets/images/pricing/pricing-icon-03.svg" alt="" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">97 %</h4>
                                            <p className="desc text-decoration-none">
                                                Of users cannot identify a
                                                sophisticated phishing email
                                            </p>
                                        </div>
                                        <div className="pricing-shape-area">
                                            <img
                                                src="assets/images/pricing/pricing-bg-shape.svg"
                                                alt=""
                                                className="one"
                                            />
                                            <img
                                                src="assets/images/pricing/pricing-shape-01.svg"
                                                alt=""
                                                className="two"
                                            />
                                            <img
                                                src="assets/images/pricing/pricing-shape-02.svg"
                                                alt=""
                                                className="three"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="pricing-wrapper">
                                        <div className="icon">
                                            <img src="assets/images/pricing/pricing-icon-04.svg" alt="" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">A2 Optimized Plugin</h4>
                                            <p className="desc text-decoration-none">global data centers</p>
                                        </div>
                                        <div className="pricing-shape-area">
                                            <img
                                                src="assets/images/pricing/pricing-bg-shape.svg"
                                                alt=""
                                                className="one"
                                            />
                                            <img
                                                src="assets/images/pricing/pricing-shape-01.svg"
                                                alt=""
                                                className="two"
                                            />
                                            <img
                                                src="assets/images/pricing/pricing-shape-02.svg"
                                                alt=""
                                                className="three"
                                            />
                                        </div>
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DataLossFeature