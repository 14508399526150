import React from 'react'

function DataLossFeatureThree() {
    return (
        <>
            <div className="rts-hosting-feature bg-white section__padding pb-0">
                <div className="container">
                <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6">
                            <div className="hosting-feature wordpress">
                                <h2
                                    className="hosting-feature__title hhosting-title"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Continues Dark Web Monitoring
                                </h2>
                                <p
                                    className="hosting-feature__desc hhosting-desc"
                                    data-sal="slide-down"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    Guardz continuously the dark web to identify leaked credentials that may lead to the risk of account compromise. Bad actors frequently begin their attack chain by acquiring stolen user names and passwords that give them access to private repositories.
                                    <br/><br/> Guardz ensures that employees’ and clients’ digital assets remain secure from targeted attacks by cybercriminals for financial gain or other malicious purposes.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 col-xl-6"
                            data-sal="slide-left"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            <div className="hosting-feature-image">
                                <div className="hosting-feature-image__image2 wordpress">
                                    <div className="h-img-two text-center">
                                        <img
                                            src="https://addicnet.com/wp-content/uploads/2024/06/Secure-All-Inbound-Emails.jpg"
                                            alt=""
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DataLossFeatureThree