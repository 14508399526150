import React from 'react'

function DataLossFeatureTwo() {
    return (
        <>
            <div className="rts-hosting-feature bg-white section__padding pb-0 sc-pd">
                <div className="container">
                    <div className="row align-items-center">
                    <div
                            className="col-lg-6 col-xl-6 order-lg-1 order-xl-1 order-md-2 order-sm-2  order-2 "
                            data-sal="slide-left"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            <div className="hosting-feature-image">
                                <div className="hosting-feature-image__image2 wordpress">
                                    <div className="h-img-two text-start">
                                        <img
                                            src="https://addicnet.com/wp-content/uploads/2024/06/Secure-All-Inbound-Emails.jpg"
                                            alt=""
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 oorder-lg-2 order-xl-2 order-md-1 order-sm-1  order-1 ">
                            <div className="hosting-feature wordpress">
                                <h2
                                    className="hosting-feature__title hhosting-title"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Active Endpoint Protection
                                </h2>
                                <p
                                    className="hosting-feature__desc hhosting-desc"
                                    data-sal="slide-down"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    The Guardz Endpoint Protection provides an instrumental layer of security by pairing it with Microsoft Defender Antivirus to manage endpoint security for your organization proactively. Performing ongoing scans in real-time on each device, the solution uncovers potential gaps in security posture, alignment with Defender policy settings, and detection of real-world threats.
                                    <br/><br/> Strengthening internal security, Guardz assures your organization’s digital assets are protected against targeted digital attacks that exploit internal weaknesses. Guardz identifies these threats and provides real-time responses, including automated remediations, generating playbooks to address any issues, and offering a valuable snapshot of all processes for thorough investigation and research.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </>
    )
}

export default DataLossFeatureTwo