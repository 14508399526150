import React, { useState } from 'react'

function SecurityFaqtwo() {
    const [activeIndexTwo, setActiveIndexTwo] = useState(0); // Default to the first accordion item
  
    const handleToggleTwo = (index) => {
      setActiveIndexTwo((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            <div className="rts-hosting-faq section__padding pt-5 pb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        
                        <div className="col-md-6">
                            <div className='faqs-img-two'>
                                <img src="https://addicnet.com/wp-content/uploads/2024/06/3.-Analyze.png" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rts-section h-gaurdz-heading">
                                <h2
                                    className="rts-section__title mb-0"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    Guardz Phishing Protection 
                                    in Action
                                </h2>
                            </div>
                            <div
                                className="rts-faq__accordion"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                <div className="accordion accordion-flush" id="rts-accordion">
                                    <div className={`accordion-item ${activeIndexTwo === 0 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(0)}>
                                        <div className="accordion-header" id="one">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 0 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 0}
                                              >
                                                Continuous Scanning
                                            </h4>
                                        </div>
                                        <div
                                           id="item__one"
                                           className={`accordion-collapse collapse ${activeIndexTwo === 0 ? "show" : ""}`}
                                           aria-labelledby="one"
                                           data-bs-parent="#rts-accordion"
                                         
                                        >
                                            <div className="accordion-body">
                                            Guardz continuously scans for all inbound traffic with advanced anti-phishing email protection solution.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexTwo === 1 ? "active" : ""}`}
                                    onClick={() => handleToggleTwo(1)}>
                                        <div className="accordion-header" id="two">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 1 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 1}
                                            >
                                                Automated Detection
                                            </h4>
                                        </div>
                                        <div
                                            id="item__two"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 1 ? "show" : ""}`}
                                            aria-labelledby="two"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Guardz initiates email detection through anti-phishing and anti-malware engines powered by AI.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexTwo === 2 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(2)}
                                    >
                                        <div className="accordion-header" id="three">
                                            <h4
                                                 className={`accordion-button ${activeIndexTwo === 2 ? "" : "collapsed"}`}
                                                 aria-expanded={activeIndexTwo === 2}
                                            >
                                                Threat Quarantine
                                            </h4>
                                        </div>
                                        <div
                                            id="item__three"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 2 ? "show" : ""}`}
                                            aria-labelledby="three"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Social engineering is a psychological method used by cybercriminals to exploit human trust and gain access to sensitive information. A common form of social engineering is phishing attacks, where attackers impersonate trusted entities via email, text, or phone calls with the intent to deceive individuals into revealing personal data.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexTwo === 3 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(3)}
                                    >
                                        <div className="accordion-header" id="four">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 3 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 3}
                                            >
                                               Actionable Real-Time Alerts
                                            </h4>
                                        </div>
                                        <div
                                            id="item__four"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 3 ? "show" : ""}`}
                                            aria-labelledby="four"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Guardz monitors internet browsing to detect potential phishing attempts and delivers real-time alerts to system admins, enabling timely responses.
                                            </div>
                                        </div>
                                    </div>    
                                    <div className={`accordion-item ${activeIndexTwo === 4 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(4)}
                                    >
                                        <div className="accordion-header" id="five">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 4 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 4}
                                            >
                                               Active Awareness Training
                                            </h4>
                                        </div>
                                        <div
                                            id="item__five"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 4 ? "show" : ""}`}
                                            aria-labelledby="five"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                                The platform provides ongoing, active cyber awareness training for employees, fostering a culture of caution and vigilance.
                                            </div>
                                        </div>
                                    </div>  
                                    <div className={`accordion-item ${activeIndexTwo === 5 ? "active" : ""}`}
                                        onClick={() => handleToggleTwo(5)}
                                    >
                                        <div className="accordion-header" id="six">
                                            <h4
                                                className={`accordion-button ${activeIndexTwo === 5 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexTwo === 5}
                                            >
                                               AI Phishing Simulation
                                            </h4>
                                        </div>
                                        <div
                                            id="item__six"
                                            className={`accordion-collapse collapse ${activeIndexTwo === 5 ? "show" : ""}`}
                                            aria-labelledby="six"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Utilizing cutting-edge generative AI, phishing simulation emails are crafted on the fly based on custom inputs, targeting groups of employees and reporting on pass/fail status.
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecurityFaqtwo