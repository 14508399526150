import React, { useState } from 'react'

function DataLossFaq() {
    const [activeIndexOne, setActiveIndexOne] = useState(0); // Default to the first accordion item
  
    const handleToggleOne = (index) => {
      setActiveIndexOne((prevIndex) => (prevIndex === index ? null : index));
    };
    return (
        <>
            <div className="rts-hosting-faq section__padding pb-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="rts-section text-center">
                            <h2
                                className="rts-section__title mb-0"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                              Cyber Threats that are Weakening
                              <br/>Your Data Security
                            </h2>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="rts-faq__accordion"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                <div className="accordion accordion-flush" id="rts-accordion">
                                    <div className={`accordion-item ${activeIndexOne === 0 ? "active" : ""}`}
                                        onClick={() => handleToggleOne(0)}>
                                        <div className="accordion-header" id="one">
                                            <h4
                                                className={`accordion-button ${activeIndexOne === 0 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexOne === 0}
                                              >
                                                Account Hijacking
                                            </h4>
                                        </div>
                                        <div
                                           id="item__one"
                                           className={`accordion-collapse collapse ${activeIndexOne === 0 ? "show" : ""}`}
                                           aria-labelledby="one"
                                           data-bs-parent="#rts-accordion"
                                         
                                        >
                                            <div className="accordion-body">
                                            Unauthorized access to confidential company data end systems can occur in the cloud due to weak security measures, poor configurations, or lack of MFA.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexOne === 1 ? "active" : ""}`}
                                    onClick={() => handleToggleOne(1)}>
                                        <div className="accordion-header" id="two">
                                            <h4
                                                className={`accordion-button ${activeIndexOne === 1 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexOne === 1}
                                            >
                                               Excessive Permissions
                                            </h4>
                                        </div>
                                        <div
                                            id="item__two"
                                            className={`accordion-collapse collapse ${activeIndexOne === 1 ? "show" : ""}`}
                                            aria-labelledby="two"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Granting cloud access permissions often disregard the principles of least privilege, resulting in excessive authority and increased risk regarding the sovereignty and potential destruction of data.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexOne === 2 ? "active" : ""}`}
                                        onClick={() => handleToggleOne(2)}
                                    >
                                        <div className="accordion-header" id="three">
                                            <h4
                                                 className={`accordion-button ${activeIndexOne === 2 ? "" : "collapsed"}`}
                                                 aria-expanded={activeIndexOne === 2}
                                            >                                               
                                                Social Engineering
                                            </h4>
                                        </div>
                                        <div
                                            id="item__three"
                                            className={`accordion-collapse collapse ${activeIndexOne === 2 ? "show" : ""}`}
                                            aria-labelledby="three"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            Social engineering is a psychological method used by cybercriminals to exploit human trust and gain access to sensitive information. A common form of social engineering is phishing attacks, where attackers impersonate trusted entities via email, text, or phone calls with the intent to deceive individuals into revealing personal data.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`accordion-item ${activeIndexOne === 3 ? "active" : ""}`}
                                        onClick={() => handleToggleOne(3)}
                                    >
                                        <div className="accordion-header" id="four">
                                            <h4
                                                className={`accordion-button ${activeIndexOne === 3 ? "" : "collapsed"}`}
                                                aria-expanded={activeIndexOne === 3}
                                            >
                                               Malicious Software
                                            </h4>
                                        </div>
                                        <div
                                            id="item__four"
                                            className={`accordion-collapse collapse ${activeIndexOne === 3 ? "show" : ""}`}
                                            aria-labelledby="four"
                                            data-bs-parent="#rts-accordion"
                                        >
                                            <div className="accordion-body">
                                            If an attack leads to a data breach involving customers’ personal data, businesses could face hefty regulatory fines, especially under laws like GDPR.
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='faqs-img'>
                                <img src="https://addicnet.com/wp-content/uploads/2024/06/2.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataLossFaq