import React from 'react'

function DedicatedHostingFeatureThree() {
    return (
        <>
            <section className="rts-feature section__padding">
                <div className="container">
                    <div className="row g-30 align-items-center">
                        <div className="col-lg-6">
                            <div className="rts-feature-section dedicated">
                                <h2 className="title">Included with Every Dedicated Hosting Plan</h2>
                                <p>
                                    Own your virtual presence with the power of Hostie with 1.5 million
                                    websites already under our care,
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                        >
                                            <path
                                                d="M19.8333 0C30.787 0 39.6667 8.87969 39.6667 19.8333C39.6667 30.787 30.787 39.6667 19.8333 39.6667C8.87969 39.6667 0 30.787 0 19.8333C0 8.87969 8.87969 0 19.8333 0ZM19.8333 2.08772C10.0327 2.08772 2.08772 10.0327 2.08772 19.8333C2.08772 29.634 10.0327 37.5789 19.8333 37.5789C29.634 37.5789 37.5789 29.634 37.5789 19.8333C37.5789 10.0327 29.634 2.08772 19.8333 2.08772ZM31.0101 8.65662C31.3863 9.03291 31.4542 9.71168 31.0101 10.1329L23.4306 17.7122C23.798 18.3339 24.0088 19.059 24.0088 19.8333C24.0088 22.1394 22.1394 24.0088 19.8333 24.0088C17.5273 24.0088 15.6579 22.1394 15.6579 19.8333C15.6579 17.5273 17.5273 15.6579 19.8333 15.6579C20.6077 15.6579 21.3328 15.8687 21.9544 16.236L29.5338 8.65662C29.9415 8.24896 30.6024 8.24896 31.0101 8.65662ZM19.8333 17.7456C18.6803 17.7456 17.7456 18.6803 17.7456 19.8333C17.7456 20.9864 18.6803 21.9211 19.8333 21.9211C20.9864 21.9211 21.9211 20.9864 21.9211 19.8333C21.9211 18.6803 20.9864 17.7456 19.8333 17.7456ZM32.1693 14.1715C32.9788 15.9325 33.4035 17.8539 33.4035 19.8333C33.4035 20.4098 32.9362 20.8772 32.3596 20.8772C31.7831 20.8772 31.3158 20.4098 31.3158 19.8333C31.3158 18.1565 30.9568 16.5323 30.2724 15.0435C30.0316 14.5197 30.261 13.8998 30.7849 13.659C31.3087 13.4183 31.9285 13.6477 32.1693 14.1715ZM19.8333 6.26316C21.831 6.26316 23.7695 6.6958 25.5435 7.51973C26.0664 7.76258 26.2934 8.38331 26.0506 8.90617C25.8077 9.42904 25.187 9.65604 24.6641 9.41319C23.1642 8.71657 21.5257 8.35088 19.8333 8.35088C13.4917 8.35088 8.35088 13.4917 8.35088 19.8333C8.35088 20.4098 7.88353 20.8772 7.30702 20.8772C6.73051 20.8772 6.26316 20.4098 6.26316 19.8333C6.26316 12.3387 12.3387 6.26316 19.8333 6.26316Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">
                                        Security, &amp; Updates
                                    </h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            width={48}
                                            height={32}
                                            viewBox="0 0 48 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M15.1992 20H23.1992V5.60004H15.1992V20ZM16.7992 7.20004H21.5992V18.4H16.7992V7.20004Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M24.7988 20H32.7988V5.60004H24.7988V20ZM26.3988 7.20004H31.1988V18.4H26.3988V7.20004Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M5.59961 20H13.5996V5.60004H5.59961V20ZM7.19961 7.20004H11.9996V18.4H7.19961V7.20004Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M34.3984 20H42.3984V5.60004H34.3984V20ZM35.9984 7.20004H40.7984V18.4H35.9984V7.20004Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M47.2 10.4C47.6418 10.4 48 10.0418 48 9.60005V3.20005C48 1.87455 46.9255 0.800049 45.6 0.800049H2.4C1.0745 0.800049 0 1.87455 0 3.20005V9.60005C0 10.0418 0.3582 10.4 0.8 10.4C1.2418 10.4 1.6 10.7582 1.6 11.2C1.6 11.6418 1.2418 12 0.8 12C0.3582 12 0 12.3582 0 12.8V24C0 24.4418 0.3582 24.8 0.8 24.8H4V30.4C4 30.8418 4.3582 31.2 4.8 31.2H17.6C18.0418 31.2 18.4 30.8418 18.4 30.4V28.8C18.4 28.3582 18.7582 28 19.2 28C19.4158 27.9993 19.6223 28.0879 19.7704 28.2448C19.9188 28.3913 20.0016 28.5915 20 28.8V30.4C20 30.8418 20.3582 31.2 20.8 31.2H43.2C43.6418 31.2 44 30.8418 44 30.4V24.8H47.2C47.6418 24.8 48 24.4418 48 24V12.8C48 12.3582 47.6418 12 47.2 12C46.7582 12 46.4 11.6418 46.4 11.2C46.4 10.7582 46.7582 10.4 47.2 10.4ZM42.4 29.6H40.8V26.4H39.2V29.6H37.6V26.4H36V29.6H34.4V26.4H32.8V29.6H31.2V26.4H29.6V29.6H28V26.4H26.4V29.6H24.8V26.4H23.2V29.6H21.6V28.8C21.5943 27.4769 20.5231 26.4057 19.2 26.4C17.8745 26.4 16.8 27.4745 16.8 28.8V29.6H15.2V26.4H13.6V29.6H12V26.4H10.4V29.6H8.8V26.4H7.2V29.6H5.6V24.8H42.4V29.6ZM46.4 8.93685C45.1501 9.37795 44.4944 10.7488 44.9355 11.9987C45.1771 12.6832 45.7155 13.2216 46.4 13.4632V23.2H1.6V13.4632C2.8499 13.0221 3.5056 11.6512 3.0645 10.4013C2.8229 9.71685 2.2845 9.17845 1.6 8.93685V3.20005C1.6 2.75825 1.9582 2.40005 2.4 2.40005H45.6C46.0418 2.40005 46.4 2.75825 46.4 3.20005V8.93685Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">RAM &amp; storage</h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M35 5H5C4.33696 5 3.70107 5.26339 3.23223 5.73223C2.76339 6.20107 2.5 6.83696 2.5 7.5V32.5C2.5 33.163 2.76339 33.7989 3.23223 34.2678C3.70107 34.7366 4.33696 35 5 35H35C35.663 35 36.2989 34.7366 36.7678 34.2678C37.2366 33.7989 37.5 33.163 37.5 32.5V7.5C37.5 6.83696 37.2366 6.20107 36.7678 5.73223C36.2989 5.26339 35.663 5 35 5V5ZM5 7.5H12.5V32.5H5V7.5ZM35 32.5H15V7.5H35V32.5Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">Quick &amp; easy</h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                        >
                                            <path
                                                d="M19.8333 0C30.787 0 39.6667 8.87969 39.6667 19.8333C39.6667 30.787 30.787 39.6667 19.8333 39.6667C8.87969 39.6667 0 30.787 0 19.8333C0 8.87969 8.87969 0 19.8333 0ZM19.8333 2.08772C10.0327 2.08772 2.08772 10.0327 2.08772 19.8333C2.08772 29.634 10.0327 37.5789 19.8333 37.5789C29.634 37.5789 37.5789 29.634 37.5789 19.8333C37.5789 10.0327 29.634 2.08772 19.8333 2.08772ZM31.0101 8.65662C31.3863 9.03291 31.4542 9.71168 31.0101 10.1329L23.4306 17.7122C23.798 18.3339 24.0088 19.059 24.0088 19.8333C24.0088 22.1394 22.1394 24.0088 19.8333 24.0088C17.5273 24.0088 15.6579 22.1394 15.6579 19.8333C15.6579 17.5273 17.5273 15.6579 19.8333 15.6579C20.6077 15.6579 21.3328 15.8687 21.9544 16.236L29.5338 8.65662C29.9415 8.24896 30.6024 8.24896 31.0101 8.65662ZM19.8333 17.7456C18.6803 17.7456 17.7456 18.6803 17.7456 19.8333C17.7456 20.9864 18.6803 21.9211 19.8333 21.9211C20.9864 21.9211 21.9211 20.9864 21.9211 19.8333C21.9211 18.6803 20.9864 17.7456 19.8333 17.7456ZM32.1693 14.1715C32.9788 15.9325 33.4035 17.8539 33.4035 19.8333C33.4035 20.4098 32.9362 20.8772 32.3596 20.8772C31.7831 20.8772 31.3158 20.4098 31.3158 19.8333C31.3158 18.1565 30.9568 16.5323 30.2724 15.0435C30.0316 14.5197 30.261 13.8998 30.7849 13.659C31.3087 13.4183 31.9285 13.6477 32.1693 14.1715ZM19.8333 6.26316C21.831 6.26316 23.7695 6.6958 25.5435 7.51973C26.0664 7.76258 26.2934 8.38331 26.0506 8.90617C25.8077 9.42904 25.187 9.65604 24.6641 9.41319C23.1642 8.71657 21.5257 8.35088 19.8333 8.35088C13.4917 8.35088 8.35088 13.4917 8.35088 19.8333C8.35088 20.4098 7.88353 20.8772 7.30702 20.8772C6.73051 20.8772 6.26316 20.4098 6.26316 19.8333C6.26316 12.3387 12.3387 6.26316 19.8333 6.26316Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">Unlimited bandwidth</h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={40}
                                            height={40}
                                            viewBox="0 0 40 40"
                                            fill="none"
                                        >
                                            <path
                                                d="M19.8333 0C30.787 0 39.6667 8.87969 39.6667 19.8333C39.6667 30.787 30.787 39.6667 19.8333 39.6667C8.87969 39.6667 0 30.787 0 19.8333C0 8.87969 8.87969 0 19.8333 0ZM19.8333 2.08772C10.0327 2.08772 2.08772 10.0327 2.08772 19.8333C2.08772 29.634 10.0327 37.5789 19.8333 37.5789C29.634 37.5789 37.5789 29.634 37.5789 19.8333C37.5789 10.0327 29.634 2.08772 19.8333 2.08772ZM31.0101 8.65662C31.3863 9.03291 31.4542 9.71168 31.0101 10.1329L23.4306 17.7122C23.798 18.3339 24.0088 19.059 24.0088 19.8333C24.0088 22.1394 22.1394 24.0088 19.8333 24.0088C17.5273 24.0088 15.6579 22.1394 15.6579 19.8333C15.6579 17.5273 17.5273 15.6579 19.8333 15.6579C20.6077 15.6579 21.3328 15.8687 21.9544 16.236L29.5338 8.65662C29.9415 8.24896 30.6024 8.24896 31.0101 8.65662ZM19.8333 17.7456C18.6803 17.7456 17.7456 18.6803 17.7456 19.8333C17.7456 20.9864 18.6803 21.9211 19.8333 21.9211C20.9864 21.9211 21.9211 20.9864 21.9211 19.8333C21.9211 18.6803 20.9864 17.7456 19.8333 17.7456ZM32.1693 14.1715C32.9788 15.9325 33.4035 17.8539 33.4035 19.8333C33.4035 20.4098 32.9362 20.8772 32.3596 20.8772C31.7831 20.8772 31.3158 20.4098 31.3158 19.8333C31.3158 18.1565 30.9568 16.5323 30.2724 15.0435C30.0316 14.5197 30.261 13.8998 30.7849 13.659C31.3087 13.4183 31.9285 13.6477 32.1693 14.1715ZM19.8333 6.26316C21.831 6.26316 23.7695 6.6958 25.5435 7.51973C26.0664 7.76258 26.2934 8.38331 26.0506 8.90617C25.8077 9.42904 25.187 9.65604 24.6641 9.41319C23.1642 8.71657 21.5257 8.35088 19.8333 8.35088C13.4917 8.35088 8.35088 13.4917 8.35088 19.8333C8.35088 20.4098 7.88353 20.8772 7.30702 20.8772C6.73051 20.8772 6.26316 20.4098 6.26316 19.8333C6.26316 12.3387 12.3387 6.26316 19.8333 6.26316Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">
                                        SSDs, email &amp; SSL
                                    </h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="card-feature">
                                <div className="card-feature__single">
                                    <div className="card-feature__single--icon">
                                        <svg
                                            width={37}
                                            height={29}
                                            viewBox="0 0 37 29"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M35.7716 13.1458L27.5948 4.48282C27.0923 3.94981 26.2534 3.9255 25.7204 4.42847C25.1882 4.93102 25.1635 5.77031 25.6665 6.30291L32.9845 14.0554L25.6665 21.8088C25.1635 22.3413 25.1882 23.1802 25.7204 23.6832C25.9767 23.925 26.3042 24.0447 26.6305 24.0447C26.9828 24.0447 27.3346 23.9051 27.5949 23.6297L35.7717 14.9663C36.2538 14.455 36.2538 13.6567 35.7716 13.1458Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M11.2407 21.8082L3.92312 14.0552L11.2407 6.30236C11.7432 5.76976 11.7189 4.9304 11.1863 4.42791C10.6541 3.92536 9.81434 3.94967 9.31186 4.48226L1.13507 13.1452C0.652892 13.6561 0.652892 14.4548 1.13507 14.9658L9.31227 23.6292C9.57304 23.9054 9.92442 24.0447 10.2767 24.0447C10.6029 24.0447 10.9304 23.9244 11.1863 23.6826C11.7194 23.1801 11.7432 22.3408 11.2407 21.8082Z"
                                                fill="#2D3C58"
                                            />
                                            <path
                                                d="M20.5996 0.0151505C19.8761 -0.0944488 19.199 0.402305 19.0885 1.12585L15.199 26.5844C15.0885 27.3083 15.5857 27.9851 16.3097 28.0956C16.3778 28.1057 16.4449 28.1106 16.5117 28.1106C17.1557 28.1106 17.7205 27.6408 17.8208 26.9849L21.7103 1.52634C21.8208 0.802304 21.3236 0.125648 20.5996 0.0151505Z"
                                                fill="#2D3C58"
                                            />
                                        </svg>
                                    </div>
                                    <h5 className="card-feature__single--title">PHP7/Perl/Python</h5>
                                    <p className="card-feature__single--description">
                                        If your website is slow or down then you losing customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DedicatedHostingFeatureThree