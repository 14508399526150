import React from 'react'
import { Link } from 'react-router-dom'

function HomeEightCta ({ className }) {
    return (
        <>
            <div className={`rts-cta-two shared-page-bg ${className}`}>
                <div className="container">
                    <div className="row">
                        <div className="rts-cta-two__wrapper">
                            <div className="cta__shape" />
                            <div className="cta-content">
                                <span
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                    We are here to Help you 
                                </span>
                                <h4
                                    data-sal="slide-down"
                                    data-sal-delay={300}
                                    data-sal-duration={800}
                                >
                                    Let's Secure Your Business.
                                </h4>
                            </div>
                            <div className="cta-btn">
                                <Link to="#" className="contact__us rts-btn rts-btn-secondary">
                                Talk to our Experts
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeEightCta