import React from 'react'

function RansomewareFeatureFive() {
    return (
        <>
            <div className="rts-hosting-feature bg-white section__padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6">
                            <div className="hosting-feature wordpress">
                                <h2
                                    className="hosting-feature__title hhosting-title"
                                    data-sal="slide-down"
                                    data-sal-delay={100}
                                    data-sal-duration={800}
                                >
                                   Endpoint Protection
                                </h2>
                                <p
                                    className="hosting-feature__desc hhosting-desc"
                                    data-sal="slide-down"
                                    data-sal-delay={200}
                                    data-sal-duration={800}
                                >
                                    Guardz provides an instrumental layer of security by pairing with Microsoft Defender Antivirus to proactively manage endpoint security for your organization. Performing ongoing scans in real-time on each device, the solution uncovers potential gaps in security posture, alignment with Defender policy settings and detection of real-world threats.
                                    <br/><br/> Strengthening internal security, Guardz assures your organization’s digital assets are protected against targeted digital attacks that exploit internal weaknesses. Guardz not only identifies these threats but provides real-time responses, including automated remediations, generating playbooks to address any issues, as well as offering a valuable snapshot of all processes for thorough investigation and research.
                                </p>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 col-xl-6"
                            data-sal="slide-left"
                            data-sal-delay={100}
                            data-sal-duration={800}
                        >
                            <div className="hosting-feature-image">
                                <div className="hosting-feature-image__image2 wordpress">
                                    <div className="h-img-two text-center">
                                        <img
                                            src="https://addicnet.com/wp-content/uploads/2024/06/Secure-All-Inbound-Emails.jpg"
                                            alt=""
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default RansomewareFeatureFive