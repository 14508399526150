import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import sal from 'sal.js';
import 'sal.js/dist/sal.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {Typewriter} from "react-simple-typewriter";
function HomeTenBanner() {
    useEffect(() => {
		sal({
			threshold: 0.1,
			once: true,
		});
	}, []);
    return (
        <>
            <section
                className="rts-hero-four rts-hero__four mern__hosting"
                style={{ backgroundImage: "url(assets/images/banner/banner__ten.jpg)" }}
            >
                <div className="container">
                    <div className="row g-5 justify-content-between align-items-center">
                        <div className="col-lg-10 col-xl-8 col-md-10">
                            <div
                                className="rts-hero-four__content"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                            {/* 
                                <p className="offer">
                                    Up to <span className="off">78%</span> off Dicstack managed services
                                </p>
                            */}
                                <h1
                                    className="banner__title h_hbanner-title"
                                    data-sal="slide-down"
                                    data-sal-delay={500}
                                    data-sal-duration={800}
                                >
                                    FOR  SECURE CLOUD
                                </h1>
                                <h6 className="h-typewriter-heading">
                                    Discover our expertise in :{" "}
                                    <span className="h-typewriter-text">
                                    

                                    <Typewriter
                words={[
                    "Multi-cloud Managed services",
                    "Managed Security services",
                    "Managed Hosting services",
                    "Data backup recovery services",
                    "Cloud & Cybersecurity talent subscription services",
                    "Custom solutions services",
                ]}
                loop={true} // Ensures infinite looping
                cursor
                cursorStyle=" "
                typeSpeed={100}
                deleteSpeed={50}
                delaySpeed={1000}
            />
                                    
                                    </span>
                                </h6>
                                <p
                                    className="description"
                                    data-sal="slide-down"
                                    data-sal-delay={600}
                                    data-sal-duration={800}
                                >
                                    We focus on your cloud & cyber risk protection so you can focus on accelerating your business 
                                </p>
                                <div
                                    className="feature"
                                    data-sal="slide-down"
                                    data-sal-delay={700}
                                    data-sal-duration={800}
                                >
                        
                                {/*
                                    <ul className="feature__list">
                                        <li className="feature__item">Node.js Supported </li>
                                        <li className="feature__item"> MongoDB Supported </li>
                                        <li className="feature__item"> Litespeed Web Server </li>
                                        <li className="feature__item"> 99.99% Uptime </li>
                                        <li className="feature__item"> 24/7/365 Day Support </li>
                                    </ul>
                                    */}
                                </div>
                                <div
                                    className="banner-buttons"
                                    data-sal="slide-down"
                                    data-sal-delay={800}
                                    data-sal-duration={800}
                                >
                                    {/* 
                                    <Link
                                        to="/pricing"
                                        className="rts-btn btn__long secondary__bg secondary__color"
                                    >
                                        get started <i className="fa-solid fa-chevron-right" />
                                    </Link>
                                    */}
                                    <Link
                                        to="/contact"
                                        className="rts-btn btn__long border__white white__color h-b-contact-btn"
                                    >
                                        contact us <i className="fa-solid fa-chevron-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeTenBanner