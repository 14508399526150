import React from 'react'
import { Link } from 'react-router-dom'

function HomeElevenDomainPricing({ className }) {
    return (
        <>
            <section className={`rts-domain-pricing-area pt--120 pb--120 ${className}`}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="section-title-area">
                            <h2
                                className="section-title sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={100}
                                data-sal-duration={800}
                            >
                                Every Step Towards Online Triumph
                            </h2>
                            <p
                                className="desc sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={200}
                                data-sal-duration={800}
                            >
                                Straightforward Domain Pricing
                            </p>
                        </div>
                    </div>
                    <div className="section-inner">
                        <div className="row g-5">
                            <div
                                className="col-lg-4 col-xl-3 col-md-4 col-sm-6 sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={200}
                                data-sal-duration={800}
                            >
                                <div className="pricing-wrapper">
                                    <div className="logo">
                                        <img src="assets/images/pricing/domain-01.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Think about why buy domain name in the first place
                                        </p>
                                        <div className="price-area">
                                            <span className="pre">$9.99</span>
                                            <span className="now">$6.99</span>
                                        </div>
                                        <div className="button-area">
                                            <Link to="#" className="pricing-btn rts-btn">
                                                Register
                                            </Link>
                                            <Link to="#" className="pricing-btn rts-btn border">
                                                Transfer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-xl-3 col-md-4 col-sm-6 sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={300}
                                data-sal-duration={800}
                            >
                                <div className="pricing-wrapper">
                                    <div className="logo">
                                        <img src="assets/images/pricing/domain-02.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Think about why buy domain name in the first place
                                        </p>
                                        <div className="price-area">
                                            <span className="pre">$9.99</span>
                                            <span className="now">$6.99</span>
                                        </div>
                                        <div className="button-area">
                                            <Link to="#" className="pricing-btn rts-btn">
                                                Register
                                            </Link>
                                            <Link to="#" className="pricing-btn rts-btn border">
                                                Transfer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-xl-3 col-md-4 col-sm-6 sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={200}
                                data-sal-duration={800}
                            >
                                <div className="pricing-wrapper">
                                    <div className="logo">
                                        <img src="assets/images/pricing/domain-03.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Think about why buy domain name in the first place
                                        </p>
                                        <div className="price-area">
                                            <span className="pre">$9.99</span>
                                            <span className="now">$6.99</span>
                                        </div>
                                        <div className="button-area">
                                            <Link to="#" className="pricing-btn rts-btn">
                                                Register
                                            </Link>
                                            <Link to="#" className="pricing-btn rts-btn border">
                                                Transfer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-xl-3 col-md-4 col-sm-6 sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={300}
                                data-sal-duration={800}
                            >
                                <div className="pricing-wrapper">
                                    <div className="logo">
                                        <img src="assets/images/pricing/domain-04.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Think about why buy domain name in the first place
                                        </p>
                                        <div className="price-area">
                                            <span className="pre">$9.99</span>
                                            <span className="now">$6.99</span>
                                        </div>
                                        <div className="button-area">
                                            <Link to="#" className="pricing-btn rts-btn">
                                                Register
                                            </Link>
                                            <Link to="#" className="pricing-btn rts-btn border">
                                                Transfer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-xl-3 col-md-4 col-sm-6 sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={200}
                                data-sal-duration={800}
                            >
                                <div className="pricing-wrapper">
                                    <div className="logo">
                                        <img src="assets/images/pricing/domain-05.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Think about why buy domain name in the first place
                                        </p>
                                        <div className="price-area">
                                            <span className="pre">$9.99</span>
                                            <span className="now">$6.99</span>
                                        </div>
                                        <div className="button-area">
                                            <Link to="#" className="pricing-btn rts-btn">
                                                Register
                                            </Link>
                                            <Link to="#" className="pricing-btn rts-btn border">
                                                Transfer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-xl-3 col-md-4 col-sm-6 sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={300}
                                data-sal-duration={800}
                            >
                                <div className="pricing-wrapper">
                                    <div className="logo">
                                        <img src="assets/images/pricing/domain-06.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Think about why buy domain name in the first place
                                        </p>
                                        <div className="price-area">
                                            <span className="pre">$9.99</span>
                                            <span className="now">$6.99</span>
                                        </div>
                                        <div className="button-area">
                                            <Link to="#" className="pricing-btn rts-btn">
                                                Register
                                            </Link>
                                            <Link to="#" className="pricing-btn rts-btn border">
                                                Transfer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-xl-3 col-md-4 col-sm-6 sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={200}
                                data-sal-duration={800}
                            >
                                <div className="pricing-wrapper">
                                    <div className="logo">
                                        <img src="assets/images/pricing/domain-07.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Think about why buy domain name in the first place
                                        </p>
                                        <div className="price-area">
                                            <span className="pre">$9.99</span>
                                            <span className="now">$6.99</span>
                                        </div>
                                        <div className="button-area">
                                            <Link to="#" className="pricing-btn rts-btn">
                                                Register
                                            </Link>
                                            <Link to="#" className="pricing-btn rts-btn border">
                                                Transfer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-xl-3 col-md-4 col-sm-6 sal-animate"
                                data-sal="slide-down"
                                data-sal-delay={300}
                                data-sal-duration={800}
                            >
                                <div className="pricing-wrapper">
                                    <div className="logo">
                                        <img src="assets/images/pricing/domain-08.svg" alt="" />
                                    </div>
                                    <div className="content">
                                        <p className="desc">
                                            Think about why buy domain name in the first place
                                        </p>
                                        <div className="price-area">
                                            <span className="pre">$9.99</span>
                                            <span className="now">$6.99</span>
                                        </div>
                                        <div className="button-area">
                                            <Link to="#" className="pricing-btn rts-btn">
                                                Register
                                            </Link>
                                            <Link to="#" className="pricing-btn rts-btn border">
                                                Transfer
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeElevenDomainPricing